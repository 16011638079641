import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "offcanvas offcanvas-bottom rounded-top full-size",
  tabindex: "-1",
  id: "offcanvasBottom10",
  "aria-labelledby": "offcanvasBottomLabel10"
}
const _hoisted_2 = { class: "offcanvas-header border-bottom" }
const _hoisted_3 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_4 = {
  type: "button",
  class: "text-reset back-icon",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_5 = { class: "offcanvas-body" }
const _hoisted_6 = { class: "each-field mb-3" }
const _hoisted_7 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_8 = { class: "each-field mb-3" }
const _hoisted_9 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_10 = { class: "each-field" }
const _hoisted_11 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_12 = { class: "each-field mb-3" }
const _hoisted_13 = { class: "input-group mb-3" }
const _hoisted_14 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_15 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = { class: "each-field" }
const _hoisted_18 = { class: "password-container" }
const _hoisted_19 = ["type"]
const _hoisted_20 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_21 = { class: "d-grid mt-4" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = {
  class: "offcanvas offcanvas-bottom rounded-top medium-size",
  tabindex: "-1",
  id: "offcanvasBottom11",
  "aria-labelledby": "offcanvasBottomLabel11"
}
const _hoisted_26 = { class: "offcanvas-body" }
const _hoisted_27 = { class: "each-field mb-3" }
const _hoisted_28 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_29 = { class: "each-field" }
const _hoisted_30 = { class: "password-container" }
const _hoisted_31 = ["type"]
const _hoisted_32 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_33 = { class: "d-grid mt-4" }
const _hoisted_34 = ["disabled"]

import { ref, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'LoginMob',
  setup(__props) {

const store = useStore();
const $toast = useToast();

const register = reactive({
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  phone: "",
});

const errors = ref({
  email: false,
  password: false,
  phone: false,
  first_name: false,
  last_name: false,
});
const eMsg = ref({
  email: "This field is required",
  password: "This field is required",
  phone: "This field is required",
  first_name: "This field is required",
  last_name: "This field is required",
});

const passwordVisible = ref(false);
const loading = ref(false);
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;

const handleContinue = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (register.first_name == "") {
    errors.value.first_name = true;
    return;
  } else {
    errors.value.first_name = false;
  }
  if (register.last_name == "") {
    errors.value.last_name = true;
    return;
  } else {
    errors.value.last_name = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(register.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }

  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/auth/register",
      details: {
        email: register.email,
        first_name: register.first_name,
        last_name: register.last_name,
        password: register.password,
        phone: register.phone,
      },
    })
    .then((resp) => {
      loading.value = false;
      // console.log(resp);
      store.commit("setUser", resp.data);
      useToast().success("Account created successfully");
      window.location.href = "/my-account";
    }). catch((err) => {
      loading.value = false;
    })
};

const Login = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (register.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }
  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/auth/login",
      details: { email: register.email, password: register.password },
    })
    .then((resp) => {
      loading.value = false;
      // console.log(resp);
      store.commit("setUser", resp.data);
      useToast().success("Login Successful");
      window.location.href = "/my-account";
    })
    .catch(() => {
      loading.value = false;
    });
};

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", _hoisted_4, [
            _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", null, [
            _createElementVNode("h5", {
              class: "offcanvas-title text-center ms-4",
              id: "offcanvasBottomLabel10"
            }, " Create an account ")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-gray my-4" }, "Enter your details to create an account", -1)),
        _createElementVNode("div", _hoisted_6, [
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            class: "mb-2 fs-6",
            for: "email"
          }, [
            _createTextVNode("Email Address "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((register.email) = $event)),
              type: "email",
              class: "form-control border-0 bg-gray",
              placeholder: "e.g joedoe@gmail.com"
            }, null, 512), [
              [_vModelText, register.email]
            ])
          ]),
          (errors.value.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(eMsg.value.email), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[11] || (_cache[11] = _createElementVNode("label", {
            class: "mb-2",
            for: "email"
          }, [
            _createTextVNode("First Name "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((register.first_name) = $event)),
              type: "email",
              class: "form-control border-0 bg-gray",
              placeholder: "e.g joe"
            }, null, 512), [
              [_vModelText, register.first_name]
            ])
          ]),
          (errors.value.first_name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(eMsg.value.first_name), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[12] || (_cache[12] = _createElementVNode("label", {
            class: "mb-2",
            for: "email"
          }, [
            _createTextVNode("Last Name "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((register.last_name) = $event)),
              type: "text",
              class: "form-control border-0 bg-gray",
              placeholder: "e.g doe"
            }, null, 512), [
              [_vModelText, register.last_name]
            ])
          ]),
          (errors.value.last_name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(eMsg.value.last_name), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[14] || (_cache[14] = _createElementVNode("label", {
            for: "meter",
            class: "mb-2"
          }, [
            _createTextVNode("Phone Number "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_Iconify, {
                icon: "twemoji:flag-nigeria",
                style: {"width":"24px","height":"24x"}
              }),
              _cache[13] || (_cache[13] = _createElementVNode("span", {
                class: "pe-2",
                style: {"border-right":"1px solid gray"}
              }, " +234", -1))
            ]),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((register.phone) = $event)),
              type: "tel",
              class: "form-control border-gray bg-gray",
              placeholder: "081*******",
              "aria-label": "Username",
              "aria-describedby": "basic-addon1"
            }, null, 512), [
              [_vModelText, register.phone]
            ])
          ]),
          (errors.value.phone)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(eMsg.value.phone), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _cache[15] || (_cache[15] = _createElementVNode("label", {
              class: "mb-2",
              for: "email"
            }, [
              _createTextVNode("Password "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", _hoisted_18, [
              _withDirectives(_createElementVNode("input", {
                type: passwordVisible.value ? 'text' : 'password',
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((register.password) = $event)),
                class: "form-control border-gray bg-gray",
                placeholder: "......"
              }, null, 8, _hoisted_19), [
                [_vModelDynamic, register.password]
              ]),
              _createElementVNode("span", { onClick: togglePasswordVisibility }, [
                _createVNode(_component_Iconify, {
                  icon: 
                  passwordVisible.value ? 'solar:eye-outline' : 'ph:eye-slash-duotone'
                ,
                  class: "toggle-eye"
                }, null, 8, ["icon"])
              ])
            ]),
            (errors.value.password)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(eMsg.value.password), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _cache[17] || (_cache[17] = _createElementVNode("p", { class: "text-gray" }, "Password must contain", -1)),
        _cache[18] || (_cache[18] = _createElementVNode("span", { class: "text-gray" }, " •   8-20 characters ", -1)),
        _cache[19] || (_cache[19] = _createElementVNode("span", { class: "text-gray" }, " •   Including numbers, letters and special character (!&*/?..) ", -1)),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (handleContinue()), ["prevent"])),
            class: "p-2 border-gray rounded btn-primary fw-bold mb-2",
            disabled: loading.value
          }, [
            (loading.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_23, "Loading..."))
              : (_openBlock(), _createElementBlock("span", _hoisted_24, "Create an account"))
          ], 8, _hoisted_22)
        ]),
        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "text-center" }, [
          _createElementVNode("p", { class: "text-gray" }, [
            _createTextVNode(" Have an account already? "),
            _createElementVNode("span", {
              class: "text-primary",
              "data-bs-toggle": "offcanvas",
              "data-bs-target": "#offcanvasBottom11",
              "aria-controls": "offcanvasBottom11"
            }, " Sign In")
          ])
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _cache[25] || (_cache[25] = _createElementVNode("div", { class: "offcanvas-header border-bottom" }, [
        _createElementVNode("div", { class: "d-flex justify-content-center align-items-center" }, [
          _createElementVNode("h5", {
            class: "offcanvas-title text-center",
            id: "offcanvasBottomLabel11"
          }, " Sign In ")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_26, [
        _cache[23] || (_cache[23] = _createElementVNode("p", { class: "text-gray my-4 fs-14" }, " Enter your registered details to continue ", -1)),
        _createElementVNode("div", _hoisted_27, [
          _cache[21] || (_cache[21] = _createElementVNode("label", {
            class: "mb-2 fs-6",
            for: "email"
          }, [
            _createTextVNode("Email Address "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((register.email) = $event)),
              type: "email",
              class: "form-control border-gray bg-gray",
              placeholder: "e.g joedoe@gmail.com"
            }, null, 512), [
              [_vModelText, register.email]
            ])
          ]),
          (errors.value.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(eMsg.value.email), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_29, [
          _cache[22] || (_cache[22] = _createElementVNode("label", {
            class: "mb-2",
            for: "email"
          }, [
            _createTextVNode("Password "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_30, [
            _withDirectives(_createElementVNode("input", {
              type: passwordVisible.value ? 'text' : 'password',
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((register.password) = $event)),
              class: "form-control border-gray bg-gray",
              placeholder: "......"
            }, null, 8, _hoisted_31), [
              [_vModelDynamic, register.password]
            ]),
            _createElementVNode("span", { onClick: togglePasswordVisibility }, [
              _createVNode(_component_Iconify, {
                icon: 
                passwordVisible.value ? 'solar:eye-outline' : 'ph:eye-slash-duotone'
              ,
                class: "toggle-eye"
              }, null, 8, ["icon"])
            ])
          ]),
          (errors.value.password)
            ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(eMsg.value.password), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("button", {
            disabled: loading.value,
            onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (Login()), ["prevent"])),
            class: "p-2 border-gray rounded btn-primary fw-bold mb-2"
          }, _toDisplayString(loading.value ? "Loading..." : "Sign In"), 9, _hoisted_34)
        ]),
        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "text-center" }, [
          _createElementVNode("p", { class: "text-gray fs-14" }, [
            _createTextVNode(" Don't have an account? "),
            _createElementVNode("span", {
              class: "text-primary fs-14",
              "data-bs-toggle": "offcanvas",
              "data-bs-target": "#offcanvasBottom10",
              "aria-controls": "offcanvasBottom10"
            }, " Sign Up")
          ])
        ], -1))
      ])
    ])
  ], 64))
}
}

})