import { RouteRecordRaw} from "vue-router";
import LandingPage from "@/views/public/landing-page.vue"
import FirstView from "@/views/public/first-app-view.vue"



const landingPageRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "First View",
    component: FirstView,
  },
  {
    path: "/landing-page",
    component : LandingPage,
    name: "Landing Page"
  },


];

export default landingPageRoutes;