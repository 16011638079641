<script lang="ts" setup>
import MyAccount from "@/components/Dashboard/my-account.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import LoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";
import { useRouter } from "vue-router";
import { ref, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
const router = useRouter();
const passwordVisible = ref(false);
const showPassword = ref(false);
const passwordVisibility = ref(false);

const store = useStore();
const $toast = useToast();

const passwordUpdate = reactive({
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
});

const eMsg = reactive({
  oldPassword: "This field is required",
  newPassword: "This field is required",
  confirmNewPassword: "This field is required",
});

const errors = ref({
  oldPassword: false,
  newPassword: false,
  confirmNewPassword: false,
});

const updatePassword = () => {
  if (passwordUpdate.oldPassword == "") {
    errors.value.oldPassword = true;
    return;
  } else {
    errors.value.oldPassword = false;
  }

  if (passwordUpdate.newPassword == "") {
    errors.value.newPassword = true;
    return;
  } else {
    errors.value.newPassword = false;
  }

  if (passwordUpdate.confirmNewPassword == "") {
    errors.value.confirmNewPassword = true;
    return;
  } else if (passwordUpdate.newPassword !== passwordUpdate.confirmNewPassword) {
    errors.value.confirmNewPassword = true;
    eMsg.confirmNewPassword = "Password does not match";
    return;
  } else {
    errors.value.confirmNewPassword = false;
  }

  store
    .dispatch("post", {
      endpoint: "/user/password",
      details: {
        newPassword: passwordUpdate.newPassword,
        oldPassword: passwordUpdate.oldPassword,
      },
    })
    .then((resp) => {
      // console.log(resp);
      useToast().success("Password Updated uccessfully");
      window.setTimeout(() => {
        window.location.reload();
      }, 1200);
    });
};

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};

const togglePassword = () => {
  passwordVisibility.value = !passwordVisibility.value;
};

const displayPassword = () => {
  showPassword.value = !showPassword.value;
};
</script>

<template>
  <div class="page-card d-flex justify-content-center">
    <MyAccount></MyAccount>

    <div v-if="store.state.user" class="account-contents">
      <div class="mobile-screen">
        <div class="pb-3">
          <div class="back-icon e-icon me-3" @click.prevent="router.go(-1)">
            <Iconify icon="ep:arrow-left" />
          </div>
        </div>
      </div>

      <div class="desktop-screen">
        <div class="b-nav border-bottom">
          <h5>Update Password</h5>
        </div>
      </div>

      <div class="profile-page">
        <div class="row g-4">
          <div class="col-lg-6 col-sm-12">
            <div class="each-field">
              <label class="mb-2" for="password"
                >Old Password <span class="text-danger">*</span></label
              >
              <div class="password-container">
                <input
                  :type="passwordVisible ? 'text' : 'password'"
                  v-model="passwordUpdate.oldPassword"
                  class="form-control border-0 bg-gray"
                  placeholder="......"
                />
                <span @click="togglePasswordVisibility">
                  <Iconify
                    :icon="
                      passwordVisible
                        ? 'solar:eye-outline'
                        : 'ph:eye-slash-duotone'
                    "
                    class="toggle-eye"
                  />
                </span>
              </div>
              <div
                v-if="errors.oldPassword"
                class="error-msg text-danger ms-2 mb-3"
                style="font-size: 12px"
              >
                {{ eMsg.oldPassword }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="each-field">
              <label class="mb-2" for="email"
                >New Password <span class="text-danger">*</span></label
              >
              <div class="password-container">
                <input
                  v-model="passwordUpdate.newPassword"
                  :type="passwordVisibility ? 'text' : 'password'"
                  class="form-control border-0 bg-gray"
                  placeholder="......"
                />
                <span @click="togglePassword">
                  <Iconify
                    :icon="
                      passwordVisibility
                        ? 'solar:eye-outline'
                        : 'ph:eye-slash-duotone'
                    "
                    class="toggle-eye"
                  />
                </span>
              </div>
              <div
                v-if="errors.newPassword"
                class="error-msg text-danger ms-2 mb-3"
                style="font-size: 12px"
              >
                {{ eMsg.newPassword }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="each-field">
              <label class="mb-2" for="email"
                >Confirm New Password <span class="text-danger">*</span></label
              >
              <div class="password-container">
                <input
                  v-model="passwordUpdate.confirmNewPassword"
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control border-0 bg-gray"
                  placeholder="......"
                />

                <span @click="displayPassword">
                  <Iconify
                    :icon="
                      showPassword
                        ? 'solar:eye-outline'
                        : 'ph:eye-slash-duotone'
                    "
                    class="toggle-eye"
                  />
                </span>
              </div>
              <div
                v-if="errors.confirmNewPassword"
                class="error-msg text-danger ms-2 mb-3"
                style="font-size: 12px"
              >
                {{ eMsg.confirmNewPassword }}
              </div>
            </div>
          </div>
        </div>

        <div class="updateBtn mt-4">
          <button @click="updatePassword()" class="btn-primary rounded px-3">
            Save Changes
          </button>
        </div>
      </div>
    </div>
    <div v-else>
    <div class="d-flex justify-content-center my-5">
      <div class="text-center" style="width: 400px">
        <Iconify icon="lets-icons:flash-light" style="font-size: 28px" />
        <h5 class="my-4">You have not signed in</h5>
        <p class="p-text text-gray mb-4">
          Please create an account or sign in to have access to your account
        </p>

        <div class="desktop-screen">  
          <div class="d-flex align-items-center justify-content-center ">
            <button
              data-bs-target="#exampleModalToggleB"
              data-bs-toggle="modal"
              class="btn-primary auth-btn me-3"
          
            >
              Create an Account
            </button>
            <button
              data-bs-target="#exampleModalToggleC"
              data-bs-toggle="modal"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        </div>

        <div class="mobile-screen"> 
          <div class="d-flex align-items-center justify-content-center ">
            <button
              class="btn-primary auth-btn me-3"
              data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom10"
            aria-controls="offcanvasBottom10"
            >
              Create an Account
            </button>
            <button
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom11"
            aria-controls="offcanvasBottom11"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        
        </div>
      </div>
    </div>
    <LoginMob></LoginMob>
    <NewUserLogin></NewUserLogin>
  </div>
  </div>
</template>
