import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-wrapper-desk" }
const _hoisted_2 = { class: "layout-page" }
const _hoisted_3 = { class: "nav-page" }
const _hoisted_4 = { class: "hero-page" }

import loader from "@/components/Dashboard/loader.vue";
import sidebar from "@/components/Dashboard/Sidebar.vue";
import navbar from "@/components/Dashboard/Navbar.vue";
import layoutdown from "@/components/Dashboard/Footer.vue";
import BottomNavMob from "@/components/Dashboard/BottomNav.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { onBeforeMount } from "vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'layout',
  setup(__props) {

const store = useStore();
 
  const loading = computed(() =>{
  return store.state.loader;
  })
  
onBeforeMount(()=>{
 store.commit("getUser");
  // console.log('jdjddj')
  
});








 

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(sidebar),
      (loading.value)
        ? (_openBlock(), _createBlock(loader, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(navbar),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(layoutdown),
      _createVNode(BottomNavMob)
    ])
  ]))
}
}

})