<script lang="ts" setup>
import { ref, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
const store = useStore();
const $toast = useToast();

const register = reactive({
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  phone: "",
});

const errors = ref({
  email: false,
  password: false,
  phone: false,
  first_name: false,
  last_name: false,
});
const eMsg = ref({
  email: "This field is required",
  password: "This field is required",
  phone: "This field is required",
  first_name: "This field is required",
  last_name: "This field is required",
});

const passwordVisible = ref(false);
const loading = ref(false);
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;

const handleContinue = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (register.first_name == "") {
    errors.value.first_name = true;
    return;
  } else {
    errors.value.first_name = false;
  }
  if (register.last_name == "") {
    errors.value.last_name = true;
    return;
  } else {
    errors.value.last_name = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(register.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }

  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/auth/register",
      details: {
        email: register.email,
        first_name: register.first_name,
        last_name: register.last_name,
        password: register.password,
        phone: register.phone,
      },
    })
    .then((resp) => {
      loading.value = false;
      // console.log(resp);
      store.commit("setUser", resp.data);
      useToast().success("Account created successfully");
      window.location.href = "/my-account";
    }). catch((err) => {
      loading.value = false;
    })
};

const Login = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (register.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }
  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/auth/login",
      details: { email: register.email, password: register.password },
    })
    .then((resp) => {
      loading.value = false;
      // console.log(resp);
      store.commit("setUser", resp.data);
      useToast().success("Login Successful");
      window.location.href = "/my-account";
    })
    .catch(() => {
      loading.value = false;
    });
};

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};
</script>

<template>
  <div
    class="offcanvas offcanvas-bottom rounded-top full-size"
    tabindex="-1"
    id="offcanvasBottom10"
    aria-labelledby="offcanvasBottomLabel10"
  >
    <div class="offcanvas-header border-bottom">
      <div class="d-flex justify-content-center align-items-center">
        <button
          type="button"
          class="text-reset back-icon"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <Iconify icon="ep:arrow-left" />
        </button>

        <div>
          <h5
            class="offcanvas-title text-center ms-4"
            id="offcanvasBottomLabel10"
          >
            Create an account
          </h5>
        </div>
      </div>
    </div>
    <div class="offcanvas-body">
      <p class="text-gray my-4">Enter your details to create an account</p>

      <div class="each-field mb-3">
        <label class="mb-2 fs-6" for="email"
          >Email Address <span class="text-danger">*</span></label
        >
        <div>
          <input
            v-model="register.email"
            type="email"
            class="form-control border-0 bg-gray"
            placeholder="e.g joedoe@gmail.com"
          />
        </div>
        <div
          v-if="errors.email"
          class="error-msg text-danger ms-2"
          style="font-size: 12px"
        >
          {{ eMsg.email }}
        </div>
      </div>
      <div class="each-field mb-3">
        <label class="mb-2" for="email"
          >First Name <span class="text-danger">*</span></label
        >
        <div>
          <input
            v-model="register.first_name"
            type="email"
            class="form-control border-0 bg-gray"
            placeholder="e.g joe"
          />
        </div>
        <div
          v-if="errors.first_name"
          class="error-msg text-danger ms-2"
          style="font-size: 12px"
        >
          {{ eMsg.first_name }}
        </div>
      </div>
      <div class="each-field">
        <label class="mb-2" for="email"
          >Last Name <span class="text-danger">*</span></label
        >
        <div>
          <input
            v-model="register.last_name"
            type="text"
            class="form-control border-0 bg-gray"
            placeholder="e.g doe"
          />
        </div>
        <div
          v-if="errors.last_name"
          class="error-msg text-danger ms-2"
          style="font-size: 12px"
        >
          {{ eMsg.last_name }}
        </div>
      </div>
      <div class="each-field mb-3">
        <label for="meter" class="mb-2"
          >Phone Number <span class="text-danger">*</span></label
        >
        <div class="input-group mb-3">
          <div class="input-group-text" id="basic-addon1">
            <Iconify
              icon="twemoji:flag-nigeria"
              style="width: 24px; height: 24x"
            /><span class="pe-2" style="border-right: 1px solid gray">
              +234</span
            >
          </div>
          <input
            v-model="register.phone"
            type="tel"
            class="form-control border-gray bg-gray"
            placeholder="081*******"
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </div>
        <div
          v-if="errors.phone"
          class="error-msg text-danger ms-2"
          style="font-size: 12px"
        >
          {{ eMsg.phone }}
        </div>
      </div>

      <div class="col-12">
        <div class="each-field">
          <label class="mb-2" for="email"
            >Password <span class="text-danger">*</span></label
          >
          <div class="password-container">
            <input
              :type="passwordVisible ? 'text' : 'password'"
              v-model="register.password"
              class="form-control border-gray bg-gray"
              placeholder="......"
            />
            <span @click="togglePasswordVisibility">
              <Iconify
                :icon="
                  passwordVisible ? 'solar:eye-outline' : 'ph:eye-slash-duotone'
                "
                class="toggle-eye"
              />
            </span>
          </div>
          <div
            v-if="errors.password"
            class="error-msg text-danger ms-2 mb-3"
            style="font-size: 12px"
          >
            {{ eMsg.password }}
          </div>
        </div>
      </div>

      <p class="text-gray">Password must contain</p>
      <span class="text-gray"> •   8-20 characters </span>
      <span class="text-gray">
        •   Including numbers, letters and special character (!&*/?..)
      </span>

      <div class="d-grid mt-4">
        <button
          @click.prevent="handleContinue()"
          class="p-2 border-gray rounded btn-primary fw-bold mb-2"
          :disabled="loading"
        >
          <span v-if="loading">Loading...</span>
          <span v-else>Create an account</span>
        </button>
      </div>

      <div class="text-center">
        <p class="text-gray">
          Have an account already?
          <span
            class="text-primary"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom11"
            aria-controls="offcanvasBottom11"
          >
            Sign In</span
          >
        </p>
      </div>
    </div>
  </div>

  <div
    class="offcanvas offcanvas-bottom rounded-top medium-size"
    tabindex="-1"
    id="offcanvasBottom11"
    aria-labelledby="offcanvasBottomLabel11"
  >
    <div class="offcanvas-header border-bottom">
      <div class="d-flex justify-content-center align-items-center">
        <h5 class="offcanvas-title text-center" id="offcanvasBottomLabel11">
          Sign In
        </h5>
      </div>
    </div>
    <div class="offcanvas-body">
      <p class="text-gray my-4 fs-14">
        Enter your registered details to continue
      </p>

      <div class="each-field mb-3">
        <label class="mb-2 fs-6" for="email"
          >Email Address <span class="text-danger">*</span></label
        >
        <div>
          <input
            v-model="register.email"
            type="email"
            class="form-control border-gray bg-gray"
            placeholder="e.g joedoe@gmail.com"
          />
        </div>
        <div
          v-if="errors.email"
          class="error-msg text-danger ms-2"
          style="font-size: 12px"
        >
          {{ eMsg.email }}
        </div>
      </div>

      <div class="each-field">
        <label class="mb-2" for="email"
          >Password <span class="text-danger">*</span></label
        >
        <div class="password-container">
          <input
            :type="passwordVisible ? 'text' : 'password'"
            v-model="register.password"
            class="form-control border-gray bg-gray"
            placeholder="......"
          />
          <span @click="togglePasswordVisibility">
            <Iconify
              :icon="
                passwordVisible ? 'solar:eye-outline' : 'ph:eye-slash-duotone'
              "
              class="toggle-eye"
            />
          </span>
        </div>
        <div
          v-if="errors.password"
          class="error-msg text-danger ms-2 mb-3"
          style="font-size: 12px"
        >
          {{ eMsg.password }}
        </div>
      </div>
      <div class="d-grid mt-4">
        <button
          :disabled="loading"
          @click.prevent="Login()"
          class="p-2 border-gray rounded btn-primary fw-bold mb-2"
        >
          {{ loading ? "Loading..." : "Sign In" }}
        </button>
      </div>
      <div class="text-center">
        <p class="text-gray fs-14">
          Don't have an account?
          <span
            class="text-primary fs-14"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom10"
            aria-controls="offcanvasBottom10"
          >
            Sign Up</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
