import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "page-card d-flex justify-content-center" }
const _hoisted_2 = {
  key: 0,
  class: "account-contents"
}
const _hoisted_3 = { class: "mobile-screen" }
const _hoisted_4 = { class: "pb-3" }
const _hoisted_5 = { class: "profile-page" }
const _hoisted_6 = { class: "row g-4" }
const _hoisted_7 = { class: "col-lg-6 col-sm-12" }
const _hoisted_8 = { class: "each-field" }
const _hoisted_9 = { class: "password-container" }
const _hoisted_10 = ["type"]
const _hoisted_11 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_12 = { class: "col-lg-6 col-sm-12" }
const _hoisted_13 = { class: "each-field" }
const _hoisted_14 = { class: "password-container" }
const _hoisted_15 = ["type"]
const _hoisted_16 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_17 = { class: "col-lg-6 col-sm-12" }
const _hoisted_18 = { class: "each-field" }
const _hoisted_19 = { class: "password-container" }
const _hoisted_20 = ["type"]
const _hoisted_21 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_22 = { class: "updateBtn mt-4" }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { class: "d-flex justify-content-center my-5" }
const _hoisted_25 = {
  class: "text-center",
  style: {"width":"400px"}
}

import MyAccount from "@/components/Dashboard/my-account.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import LoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";
import { useRouter } from "vue-router";
import { ref, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'update-password',
  setup(__props) {

const router = useRouter();
const passwordVisible = ref(false);
const showPassword = ref(false);
const passwordVisibility = ref(false);

const store = useStore();
const $toast = useToast();

const passwordUpdate = reactive({
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
});

const eMsg = reactive({
  oldPassword: "This field is required",
  newPassword: "This field is required",
  confirmNewPassword: "This field is required",
});

const errors = ref({
  oldPassword: false,
  newPassword: false,
  confirmNewPassword: false,
});

const updatePassword = () => {
  if (passwordUpdate.oldPassword == "") {
    errors.value.oldPassword = true;
    return;
  } else {
    errors.value.oldPassword = false;
  }

  if (passwordUpdate.newPassword == "") {
    errors.value.newPassword = true;
    return;
  } else {
    errors.value.newPassword = false;
  }

  if (passwordUpdate.confirmNewPassword == "") {
    errors.value.confirmNewPassword = true;
    return;
  } else if (passwordUpdate.newPassword !== passwordUpdate.confirmNewPassword) {
    errors.value.confirmNewPassword = true;
    eMsg.confirmNewPassword = "Password does not match";
    return;
  } else {
    errors.value.confirmNewPassword = false;
  }

  store
    .dispatch("post", {
      endpoint: "/user/password",
      details: {
        newPassword: passwordUpdate.newPassword,
        oldPassword: passwordUpdate.oldPassword,
      },
    })
    .then((resp) => {
      // console.log(resp);
      useToast().success("Password Updated uccessfully");
      window.setTimeout(() => {
        window.location.reload();
      }, 1200);
    });
};

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};

const togglePassword = () => {
  passwordVisibility.value = !passwordVisibility.value;
};

const displayPassword = () => {
  showPassword.value = !showPassword.value;
};

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MyAccount),
    (_unref(store).state.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "back-icon e-icon me-3",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(router).go(-1)), ["prevent"]))
              }, [
                _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
              ])
            ])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "desktop-screen" }, [
            _createElementVNode("div", { class: "b-nav border-bottom" }, [
              _createElementVNode("h5", null, "Update Password")
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "password"
                  }, [
                    _createTextVNode("Old Password "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      type: passwordVisible.value ? 'text' : 'password',
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((passwordUpdate.oldPassword) = $event)),
                      class: "form-control border-0 bg-gray",
                      placeholder: "......"
                    }, null, 8, _hoisted_10), [
                      [_vModelDynamic, passwordUpdate.oldPassword]
                    ]),
                    _createElementVNode("span", { onClick: togglePasswordVisibility }, [
                      _createVNode(_component_Iconify, {
                        icon: 
                      passwordVisible.value
                        ? 'solar:eye-outline'
                        : 'ph:eye-slash-duotone'
                    ,
                        class: "toggle-eye"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  (errors.value.oldPassword)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(eMsg.oldPassword), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "email"
                  }, [
                    _createTextVNode("New Password "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_14, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((passwordUpdate.newPassword) = $event)),
                      type: passwordVisibility.value ? 'text' : 'password',
                      class: "form-control border-0 bg-gray",
                      placeholder: "......"
                    }, null, 8, _hoisted_15), [
                      [_vModelDynamic, passwordUpdate.newPassword]
                    ]),
                    _createElementVNode("span", { onClick: togglePassword }, [
                      _createVNode(_component_Iconify, {
                        icon: 
                      passwordVisibility.value
                        ? 'solar:eye-outline'
                        : 'ph:eye-slash-duotone'
                    ,
                        class: "toggle-eye"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  (errors.value.newPassword)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(eMsg.newPassword), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "email"
                  }, [
                    _createTextVNode("Confirm New Password "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_19, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((passwordUpdate.confirmNewPassword) = $event)),
                      type: showPassword.value ? 'text' : 'password',
                      class: "form-control border-0 bg-gray",
                      placeholder: "......"
                    }, null, 8, _hoisted_20), [
                      [_vModelDynamic, passwordUpdate.confirmNewPassword]
                    ]),
                    _createElementVNode("span", { onClick: displayPassword }, [
                      _createVNode(_component_Iconify, {
                        icon: 
                      showPassword.value
                        ? 'solar:eye-outline'
                        : 'ph:eye-slash-duotone'
                    ,
                        class: "toggle-eye"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  (errors.value.confirmNewPassword)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(eMsg.confirmNewPassword), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (updatePassword())),
                class: "btn-primary rounded px-3"
              }, " Save Changes ")
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createVNode(_component_Iconify, {
                icon: "lets-icons:flash-light",
                style: {"font-size":"28px"}
              }),
              _cache[9] || (_cache[9] = _createStaticVNode("<h5 class=\"my-4\">You have not signed in</h5><p class=\"p-text text-gray mb-4\"> Please create an account or sign in to have access to your account </p><div class=\"desktop-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button data-bs-target=\"#exampleModalToggleB\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn me-3\"> Create an Account </button><button data-bs-target=\"#exampleModalToggleC\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn\"> Login </button></div></div><div class=\"mobile-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button class=\"btn-primary auth-btn me-3\" data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom10\" aria-controls=\"offcanvasBottom10\"> Create an Account </button><button data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom11\" aria-controls=\"offcanvasBottom11\" class=\"btn-primary auth-btn\"> Login </button></div></div>", 4))
            ])
          ]),
          _createVNode(LoginMob),
          _createVNode(NewUserLogin)
        ]))
  ]))
}
}

})