<script lang="ts" setup>
import { ref, onMounted, reactive, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
const Toast = useToast();
const loading = ref(false);
const providers = ref([]);
const showSummary = ref(false);
const showForm = ref(true);
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;

const props = defineProps({
  oneBeneficiary: {},
});

const benefit: any = computed(() => {
  return props.oneBeneficiary;
});

const errors = ref({
  meter_no: false,
  provider: false,
  meter_type: false,
  amount: false,
  phone: false,
  email: false,
});

const eMsg = ref({
  meter_no: "This field is required",
  provider: "This field is required",
  meter_type: "This field is required",
  amount: "This field is required",
  phone: "This field is required",
  email: "This field is required",
});

const apiResponse:any = reactive({
  Customer_Name: "",
});

const getProvider = () => {
  store
    .dispatch("get", `/purchase/provider`)
    .then((resp) => {
      providers.value = resp.data.data;
      // console.log(providers.value);
    })
    .catch((err) => {
      console.log(err);
    });
};

const verifybenefit = () => {
  if (benefit.value.meter_no == "") {
    errors.value.meter_no = true;
    return;
  } else if (!benefit.value.meter_no.match(/^\d{11}$/)) {
    errors.value.meter_no = true;
    eMsg.value.meter_no = "Please Enter a valid Meter Number";
    return;
  } else {
    errors.value.meter_no = false;
  }

  if (benefit.value.provider == "") {
    errors.value.provider = true;
    return;
  } else {
    errors.value.provider = false;
  }

  if (benefit.value.meter_type == "") {
    errors.value.meter_type = true;
    return;
  } else {
    errors.value.meter_type = false;
  }

  if (benefit.value.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(benefit.value.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }
  if (benefit.value.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !benefit.value.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }
  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/purchase/verify",
      details: {
        meter_no: benefit.value.meter_no,
        meter_type: benefit.value.meter_type,
        provider: benefit.value.provider,
      },
    })

    .then((resp) => {
      loading.value = false 
       apiResponse.Customer_Name = resp.data.data.Customer_Name;
      useToast().success("Verified");
      showSummary.value = true;
      showForm.value = false;
    });
};

const saveBeneficiary = () => {
  store.commit("setLoader", true);
  store
    .dispatch("patch", {
      endpoint: `/beneficiary/${benefit.value.id}`,
      details: {
        name: apiResponse.Customer_Name,
        meter_no: benefit.value.meter_no,
        email: benefit.value.email,
        meter_type: benefit.value.meter_type,
        phone: benefit.value.phone,
        provider: benefit.value.provider,
      },
    })
    .then((resp) => {
      console.log(resp);
      showSummary.value = true;
      showForm.value = false;
      useToast().success("New Beneficiary Added");
      window.setTimeout(() => {
        window.location.reload();
      }, 1200);
    })

    .catch((err) => {
      console.log(err);
    });
};

onMounted(() => {
  getProvider();
});

</script>


<template>
  <div v-if="benefit">
    <div
      class="offcanvas offcanvas-end right-sheet"
      tabindex="-1"
      id="offcanvasRightD"
      aria-labelledby="offcanvasRightLabelD"
    >
      <div class="offcanvas-header border-bottom py-4 d-block">
        <div class="mb-3">
          <button
            type="button"
            class="bg-white me-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Iconify icon="ep:back" />
          </button>
          <span>Back</span>
        </div>

        <h5 id="offcanvasRightLabelD">Edit Beneficiary</h5>
      </div>
      <div class="offcanvas-body p-4">
        <div v-if="showForm">
          <form @submit.prevent="verifybenefit()">
            <div class="each-field mb-4">
              <label class="mb-2" for="meter"
                >Meter Number <span class="text-danger">*</span></label
              >
              <div>
                <input
                  v-model="benefit.meter_no"
                  type="text"
                  class="form-control border-gray bg-gray mb-2"
                  placeholder="e.g 123456789"
                />
              </div>
              <div v-if="errors.meter_no" class="error-msg">
                {{ eMsg.meter_no }}
              </div>
            </div>

            <div class="each-field mb-4">
              <label for="meter" class="mb-2"
                >Provider <span class="text-danger">*</span></label
              >
              <div class="input-group mb-3">
                <span class="input-group-text" id="inputGroup-sizing-default"
                  ><Iconify
                    icon="carbon:location"
                    class="pe-2"
                    style="
                      width: 30px;
                      height: 30px;
                      color: #818181;
                      border-right: 1px solid #c6c6c6;
                    "
                  />
                </span>
                <select
                  v-model="benefit.provider"
                  class="form-select form-select-lg mb-3"
                  aria-label="Large select example"
                >
                  <option disabled value="">select option</option>
                  =
                  <option
                    v-for="(provider, index) in providers"
                    :key="index"
                    :value="provider.serviceID"
                  >
                    {{ provider.serviceID }}
                  </option>
                </select>
              </div>
              <div v-if="errors.provider" class="error-msg">
                {{ eMsg.provider }}
              </div>
            </div>
            <div class="each-field mb-4">
              <label class="mb-2" for="meter"
                >Meter Type <span class="text-danger">*</span></label
              >
              <div>
                <select
                  v-model="benefit.meter_type"
                  class="form-select form-select-lg mb-3"
                  aria-label="Large select example"
                >
                  <option disabled value="">select option</option>
                  <option value="prepaid">prepaid</option>
                  <option value="postpaid">postpaid</option>
                </select>
              </div>
              <div v-if="errors.meter_type" class="error-msg">
                {{ eMsg.meter_type }}
              </div>
            </div>

            <div class="each-field mb-4">
              <label for="meter" class="mb-2"
                >Phone Number <span class="text-danger">*</span></label
              >
              <div class="input-group mb-3">
                <div class="input-group-text" id="basic-addon1">
                  <Iconify
                    icon="twemoji:flag-nigeria"
                    style="width: 24px; height: 24x"
                  /><span class="pe-2" style="border-right: 1px solid gray">
                    +234</span
                  >
                </div>
                <input
                  v-model="benefit.phone"
                  type="text"
                  class="form-control border-gray bg-gray"
                  placeholder="081*******"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div v-if="errors.phone" class="error-msg">{{ eMsg.phone }}</div>
            </div>

            <div class="each-field mb-4">
              <label class="mb-2" for="meter">Email Address </label>
              <div>
                <input
                  v-model="benefit.email"
                  type="text"
                  class="form-control border-gray bg-gray mb-2"
                  placeholder="e.g jojndoe@gmail.com"
                />
              </div>
              <div v-if="errors.email" class="error-msg">{{ eMsg.email }}</div>
            </div>

            <div class="d-grid mt-4">
              <button
                type="submit"
                class="border-gray rounded btn-primary text-white"
                style="height: 44px"
                :disabled="loading"
              >
                {{ loading ? "Loading..." : " Verify" }}
              </button>
            </div>
          </form>
        </div>
        <div v-if="showSummary">
          <div class="d-flex align-items-center">
            <div class="back-icon me-3" @click.prevent="router.go(-1)">
              <Iconify icon="ep:arrow-left" />
            </div>
            <p>Verified Details</p>
          </div>
          <div class="card-wrapper border rounded mt-3">
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Provider</p>
              <div class="provider-logo p-2 rounded">
                {{ benefit.provider }}
              </div>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Meter Type</p>
              <p class="p-text">{{ benefit.meter_type }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Meter Number</p>
              <p class="p-text">{{ benefit.meter_no }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Meter Name</p>
              <p class="p-text">{{ apiResponse.Customer_Name }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Phone Number</p>
              <p class="p-text">{{ benefit.phone }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Email</p>
              <p class="p-text">{{ benefit.email }}</p>
            </div>
          </div>
          <div class="d-grid mt-4">
            <button
              @click.prevent="saveBeneficiary()"
              type="submit"
              class="border-gray rounded btn-primary text-white"
              style="height: 44px"
              :disabled="loading"
            >
              {{ loading ? "Loading..." : " Save Beneficiary" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="offcanvas offcanvas-bottom full-size"
      tabindex="-1"
      id="offcanvasBottomT"
      aria-labelledby="offcanvasBottomLabelT"
    >
      <div class="offcanvas-header border-bottom pt-2 d-block">
        <div class="mb-3">
          <button
            type="button"
            class="bg-white me-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Iconify icon="ep:back" />
          </button>
          <span>Back</span>
        </div>

        <h5 id="offcanvasBottomLabelT">Edit benefit</h5>
      </div>
      <div class="offcanvas-body p-4">
        <div v-if="showForm">
          <form @submit.prevent="verifybenefit()">
            <div class="each-field mb-4">
              <label class="mb-2" for="meter"
                >Meter Number <span class="text-danger">*</span></label
              >
              <div>
                <input
                  v-model="benefit.meter_no"
                  type="text"
                  class="form-control border-gray bg-gray mb-2"
                  placeholder="e.g 123456789"
                />
              </div>
              <div v-if="errors.meter_no" class="error-msg">
                {{ eMsg.meter_no }}
              </div>
              <div></div>
            </div>

            <div class="each-field mb-4">
              <label for="meter" class="mb-2"
                >Provider <span class="text-danger">*</span></label
              >
              <div class="input-group mb-3">
                <span class="input-group-text" id="inputGroup-sizing-default"
                  ><Iconify
                    icon="carbon:location"
                    class="pe-2"
                    style="
                      width: 30px;
                      height: 30px;
                      color: #818181;
                      border-right: 1px solid #c6c6c6;
                    "
                  />
                </span>
                <select
                  v-model="benefit.provider"
                  class="form-select form-select-lg mb-3"
                  aria-label="Large select example"
                >
                  <option disabled value="">select option</option>
                  =
                  <option
                    v-for="(provider, index) in providers"
                    :key="index"
                    :value="provider.serviceID"
                  >
                    {{ provider.serviceID }}
                  </option>
                </select>
              </div>
              <div v-if="errors.provider" class="error-msg">
                {{ eMsg.provider }}
              </div>
            </div>
            <div class="each-field mb-4">
              <label class="mb-2" for="meter"
                >Meter Type <span class="text-danger">*</span></label
              >
              <div>
                <select
                  v-model="benefit.meter_type"
                  class="form-select form-select-lg mb-3"
                  aria-label="Large select example"
                >
                  <option disabled value="">select option</option>
                  <option value="prepaid">prepaid</option>
                  <option value="postpaid">postpaid</option>
                </select>
              </div>
              <div v-if="errors.meter_type" class="error-msg">
                {{ eMsg.meter_type }}
              </div>
            </div>

            <div class="each-field mb-4">
              <label for="meter" class="mb-2"
                >Phone Number <span class="text-danger">*</span></label
              >
              <div class="input-group mb-3">
                <div class="input-group-text" id="basic-addon1">
                  <Iconify
                    icon="twemoji:flag-nigeria"
                    style="width: 24px; height: 24x"
                  /><span class="pe-2" style="border-right: 1px solid gray">
                    +234</span
                  >
                </div>
                <input
                  v-model="benefit.phone"
                  type="text"
                  class="form-control border-gray bg-gray"
                  placeholder="081*******"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div v-if="errors.phone" class="error-msg">{{ eMsg.phone }}</div>
            </div>

            <div class="each-field mb-4">
              <label class="mb-2" for="meter">Email Address </label>
              <div>
                <input
                  v-model="benefit.email"
                  type="text"
                  class="form-control border-gray bg-gray mb-2"
                  placeholder="e.g jojndoe@gmail.com"
                />
              </div>
              <div v-if="errors.email" class="error-msg">{{ eMsg.email }}</div>
            </div>

            <div class="d-grid mt-4">
              <button
                type="submit"
                class="border-gray rounded btn-primary text-white"
                style="height: 44px"
                :disabled="loading"
              >
                {{ loading ? "Loading..." : " Verify" }}
              </button>
            </div>
          </form>
        </div>

        <div v-if="showSummary">
          <div class="d-flex align-items-center">
            <div class="back-icon me-3" @click.prevent="router.go(-1)">
              <Iconify icon="ep:arrow-left" />
            </div>
            <p>Verified Details</p>
          </div>
          <div class="card-wrapper border mt-3 rounded">
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Provider</p>
              <div class="provider-logo p-2 rounded">
                {{ benefit.provider }}
              </div>
            </div>
            <div
              class="provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Meter Type</p>
              <p class="p-text">{{ benefit.meter_type }}</p>
            </div>
            <div
              class="provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Meter Number</p>
              <p class="p-text">{{ benefit.meter_no }}</p>
            </div>
            <div
              class="provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Meter Name</p>
              <p class="p-text">{{ apiResponse.Customer_Name }}</p>
            </div>
            <div
              class="provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Phone Number</p>
              <p class="p-text">{{ benefit.phone }}</p>
            </div>
            <div
              class="provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Email</p>
              <p class="p-text">{{ benefit.email }}</p>
            </div>
          </div>
          <div class="d-grid mt-4">
            <button
              @click.prevent="saveBeneficiary()"
              type="submit"
              class="border-gray rounded btn-primary text-white"
              style="height: 44px"
              :disabled="loading"
            >
              {{ loading ? "Loading..." : " Save Beneficiary" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
