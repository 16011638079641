import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withModifiers as _withModifiers, unref as _unref } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "offcanvas offcanvas-end right-sheet",
  tabindex: "-1",
  id: "offcanvasRightD",
  "aria-labelledby": "offcanvasRightLabelD"
}
const _hoisted_3 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  type: "button",
  class: "bg-white me-2",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_6 = { class: "offcanvas-body p-4" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "each-field mb-4" }
const _hoisted_9 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_10 = { class: "each-field mb-4" }
const _hoisted_11 = { class: "input-group mb-3" }
const _hoisted_12 = {
  class: "input-group-text",
  id: "inputGroup-sizing-default"
}
const _hoisted_13 = ["value"]
const _hoisted_14 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_15 = { class: "each-field mb-4" }
const _hoisted_16 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_17 = { class: "each-field mb-4" }
const _hoisted_18 = { class: "input-group mb-3" }
const _hoisted_19 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_20 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_21 = { class: "each-field mb-4" }
const _hoisted_22 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_23 = { class: "d-grid mt-4" }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "d-flex align-items-center" }
const _hoisted_27 = { class: "card-wrapper border rounded mt-3" }
const _hoisted_28 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_29 = { class: "provider-logo p-2 rounded" }
const _hoisted_30 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_31 = { class: "p-text" }
const _hoisted_32 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_33 = { class: "p-text" }
const _hoisted_34 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_35 = { class: "p-text" }
const _hoisted_36 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_37 = { class: "p-text" }
const _hoisted_38 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_39 = { class: "p-text" }
const _hoisted_40 = { class: "d-grid mt-4" }
const _hoisted_41 = ["disabled"]
const _hoisted_42 = {
  class: "offcanvas offcanvas-bottom full-size",
  tabindex: "-1",
  id: "offcanvasBottomT",
  "aria-labelledby": "offcanvasBottomLabelT"
}
const _hoisted_43 = { class: "offcanvas-header border-bottom pt-2 d-block" }
const _hoisted_44 = { class: "mb-3" }
const _hoisted_45 = {
  type: "button",
  class: "bg-white me-2",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_46 = { class: "offcanvas-body p-4" }
const _hoisted_47 = { key: 0 }
const _hoisted_48 = { class: "each-field mb-4" }
const _hoisted_49 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_50 = { class: "each-field mb-4" }
const _hoisted_51 = { class: "input-group mb-3" }
const _hoisted_52 = {
  class: "input-group-text",
  id: "inputGroup-sizing-default"
}
const _hoisted_53 = ["value"]
const _hoisted_54 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_55 = { class: "each-field mb-4" }
const _hoisted_56 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_57 = { class: "each-field mb-4" }
const _hoisted_58 = { class: "input-group mb-3" }
const _hoisted_59 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_60 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_61 = { class: "each-field mb-4" }
const _hoisted_62 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_63 = { class: "d-grid mt-4" }
const _hoisted_64 = ["disabled"]
const _hoisted_65 = { key: 1 }
const _hoisted_66 = { class: "d-flex align-items-center" }
const _hoisted_67 = { class: "card-wrapper border mt-3 rounded" }
const _hoisted_68 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_69 = { class: "provider-logo p-2 rounded" }
const _hoisted_70 = { class: "provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_71 = { class: "p-text" }
const _hoisted_72 = { class: "provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_73 = { class: "p-text" }
const _hoisted_74 = { class: "provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_75 = { class: "p-text" }
const _hoisted_76 = { class: "provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_77 = { class: "p-text" }
const _hoisted_78 = { class: "provider py-3 px-1 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_79 = { class: "p-text" }
const _hoisted_80 = { class: "d-grid mt-4" }
const _hoisted_81 = ["disabled"]

import { ref, onMounted, reactive, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'EditBeneficiary',
  props: {
  oneBeneficiary: {},
},
  setup(__props) {

const router = useRouter();
const store = useStore();
const Toast = useToast();
const loading = ref(false);
const providers = ref([]);
const showSummary = ref(false);
const showForm = ref(true);
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;

const props = __props;

const benefit: any = computed(() => {
  return props.oneBeneficiary;
});

const errors = ref({
  meter_no: false,
  provider: false,
  meter_type: false,
  amount: false,
  phone: false,
  email: false,
});

const eMsg = ref({
  meter_no: "This field is required",
  provider: "This field is required",
  meter_type: "This field is required",
  amount: "This field is required",
  phone: "This field is required",
  email: "This field is required",
});

const apiResponse:any = reactive({
  Customer_Name: "",
});

const getProvider = () => {
  store
    .dispatch("get", `/purchase/provider`)
    .then((resp) => {
      providers.value = resp.data.data;
      // console.log(providers.value);
    })
    .catch((err) => {
      console.log(err);
    });
};

const verifybenefit = () => {
  if (benefit.value.meter_no == "") {
    errors.value.meter_no = true;
    return;
  } else if (!benefit.value.meter_no.match(/^\d{11}$/)) {
    errors.value.meter_no = true;
    eMsg.value.meter_no = "Please Enter a valid Meter Number";
    return;
  } else {
    errors.value.meter_no = false;
  }

  if (benefit.value.provider == "") {
    errors.value.provider = true;
    return;
  } else {
    errors.value.provider = false;
  }

  if (benefit.value.meter_type == "") {
    errors.value.meter_type = true;
    return;
  } else {
    errors.value.meter_type = false;
  }

  if (benefit.value.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(benefit.value.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }
  if (benefit.value.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !benefit.value.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }
  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/purchase/verify",
      details: {
        meter_no: benefit.value.meter_no,
        meter_type: benefit.value.meter_type,
        provider: benefit.value.provider,
      },
    })

    .then((resp) => {
      loading.value = false 
       apiResponse.Customer_Name = resp.data.data.Customer_Name;
      useToast().success("Verified");
      showSummary.value = true;
      showForm.value = false;
    });
};

const saveBeneficiary = () => {
  store.commit("setLoader", true);
  store
    .dispatch("patch", {
      endpoint: `/beneficiary/${benefit.value.id}`,
      details: {
        name: apiResponse.Customer_Name,
        meter_no: benefit.value.meter_no,
        email: benefit.value.email,
        meter_type: benefit.value.meter_type,
        phone: benefit.value.phone,
        provider: benefit.value.provider,
      },
    })
    .then((resp) => {
      console.log(resp);
      showSummary.value = true;
      showForm.value = false;
      useToast().success("New Beneficiary Added");
      window.setTimeout(() => {
        window.location.reload();
      }, 1200);
    })

    .catch((err) => {
      console.log(err);
    });
};

onMounted(() => {
  getProvider();
});


return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (benefit.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createVNode(_component_Iconify, { icon: "ep:back" })
              ]),
              _cache[16] || (_cache[16] = _createElementVNode("span", null, "Back", -1))
            ]),
            _cache[17] || (_cache[17] = _createElementVNode("h5", { id: "offcanvasRightLabelD" }, "Edit Beneficiary", -1))
          ]),
          _createElementVNode("div", _hoisted_6, [
            (showForm.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("form", {
                    onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (verifybenefit()), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[18] || (_cache[18] = _createElementVNode("label", {
                        class: "mb-2",
                        for: "meter"
                      }, [
                        _createTextVNode("Meter Number "),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((benefit.value.meter_no) = $event)),
                          type: "text",
                          class: "form-control border-gray bg-gray mb-2",
                          placeholder: "e.g 123456789"
                        }, null, 512), [
                          [_vModelText, benefit.value.meter_no]
                        ])
                      ]),
                      (errors.value.meter_no)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(eMsg.value.meter_no), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _cache[21] || (_cache[21] = _createElementVNode("label", {
                        for: "meter",
                        class: "mb-2"
                      }, [
                        _createTextVNode("Provider "),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", _hoisted_12, [
                          _createVNode(_component_Iconify, {
                            icon: "carbon:location",
                            class: "pe-2",
                            style: {"width":"30px","height":"30px","color":"#818181","border-right":"1px solid #c6c6c6"}
                          })
                        ]),
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((benefit.value.provider) = $event)),
                          class: "form-select form-select-lg mb-3",
                          "aria-label": "Large select example"
                        }, [
                          _cache[19] || (_cache[19] = _createElementVNode("option", {
                            disabled: "",
                            value: ""
                          }, "select option", -1)),
                          _cache[20] || (_cache[20] = _createTextVNode(" = ")),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providers.value, (provider, index) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: index,
                              value: provider.serviceID
                            }, _toDisplayString(provider.serviceID), 9, _hoisted_13))
                          }), 128))
                        ], 512), [
                          [_vModelSelect, benefit.value.provider]
                        ])
                      ]),
                      (errors.value.provider)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(eMsg.value.provider), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _cache[23] || (_cache[23] = _createElementVNode("label", {
                        class: "mb-2",
                        for: "meter"
                      }, [
                        _createTextVNode("Meter Type "),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((benefit.value.meter_type) = $event)),
                          class: "form-select form-select-lg mb-3",
                          "aria-label": "Large select example"
                        }, _cache[22] || (_cache[22] = [
                          _createElementVNode("option", {
                            disabled: "",
                            value: ""
                          }, "select option", -1),
                          _createElementVNode("option", { value: "prepaid" }, "prepaid", -1),
                          _createElementVNode("option", { value: "postpaid" }, "postpaid", -1)
                        ]), 512), [
                          [_vModelSelect, benefit.value.meter_type]
                        ])
                      ]),
                      (errors.value.meter_type)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(eMsg.value.meter_type), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _cache[25] || (_cache[25] = _createElementVNode("label", {
                        for: "meter",
                        class: "mb-2"
                      }, [
                        _createTextVNode("Phone Number "),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_Iconify, {
                            icon: "twemoji:flag-nigeria",
                            style: {"width":"24px","height":"24x"}
                          }),
                          _cache[24] || (_cache[24] = _createElementVNode("span", {
                            class: "pe-2",
                            style: {"border-right":"1px solid gray"}
                          }, " +234", -1))
                        ]),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((benefit.value.phone) = $event)),
                          type: "text",
                          class: "form-control border-gray bg-gray",
                          placeholder: "081*******",
                          "aria-label": "Username",
                          "aria-describedby": "basic-addon1"
                        }, null, 512), [
                          [_vModelText, benefit.value.phone]
                        ])
                      ]),
                      (errors.value.phone)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(eMsg.value.phone), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[26] || (_cache[26] = _createElementVNode("label", {
                        class: "mb-2",
                        for: "meter"
                      }, "Email Address ", -1)),
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((benefit.value.email) = $event)),
                          type: "text",
                          class: "form-control border-gray bg-gray mb-2",
                          placeholder: "e.g jojndoe@gmail.com"
                        }, null, 512), [
                          [_vModelText, benefit.value.email]
                        ])
                      ]),
                      (errors.value.email)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(eMsg.value.email), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("button", {
                        type: "submit",
                        class: "border-gray rounded btn-primary text-white",
                        style: {"height":"44px"},
                        disabled: loading.value
                      }, _toDisplayString(loading.value ? "Loading..." : " Verify"), 9, _hoisted_24)
                    ])
                  ], 32)
                ]))
              : _createCommentVNode("", true),
            (showSummary.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", {
                      class: "back-icon me-3",
                      onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_unref(router).go(-1)), ["prevent"]))
                    }, [
                      _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
                    ]),
                    _cache[27] || (_cache[27] = _createElementVNode("p", null, "Verified Details", -1))
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _cache[28] || (_cache[28] = _createElementVNode("p", { class: "text-gray p-text" }, "Provider", -1)),
                      _createElementVNode("div", _hoisted_29, _toDisplayString(benefit.value.provider), 1)
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _cache[29] || (_cache[29] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Type", -1)),
                      _createElementVNode("p", _hoisted_31, _toDisplayString(benefit.value.meter_type), 1)
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _cache[30] || (_cache[30] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Number", -1)),
                      _createElementVNode("p", _hoisted_33, _toDisplayString(benefit.value.meter_no), 1)
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _cache[31] || (_cache[31] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Name", -1)),
                      _createElementVNode("p", _hoisted_35, _toDisplayString(apiResponse.Customer_Name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                      _cache[32] || (_cache[32] = _createElementVNode("p", { class: "text-gray p-text" }, "Phone Number", -1)),
                      _createElementVNode("p", _hoisted_37, _toDisplayString(benefit.value.phone), 1)
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _cache[33] || (_cache[33] = _createElementVNode("p", { class: "text-gray p-text" }, "Email", -1)),
                      _createElementVNode("p", _hoisted_39, _toDisplayString(benefit.value.email), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("button", {
                      onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (saveBeneficiary()), ["prevent"])),
                      type: "submit",
                      class: "border-gray rounded btn-primary text-white",
                      style: {"height":"44px"},
                      disabled: loading.value
                    }, _toDisplayString(loading.value ? "Loading..." : " Save Beneficiary"), 9, _hoisted_41)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_42, [
          _createElementVNode("div", _hoisted_43, [
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("button", _hoisted_45, [
                _createVNode(_component_Iconify, { icon: "ep:back" })
              ]),
              _cache[34] || (_cache[34] = _createElementVNode("span", null, "Back", -1))
            ]),
            _cache[35] || (_cache[35] = _createElementVNode("h5", { id: "offcanvasBottomLabelT" }, "Edit benefit", -1))
          ]),
          _createElementVNode("div", _hoisted_46, [
            (showForm.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                  _createElementVNode("form", {
                    onSubmit: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (verifybenefit()), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_48, [
                      _cache[36] || (_cache[36] = _createElementVNode("label", {
                        class: "mb-2",
                        for: "meter"
                      }, [
                        _createTextVNode("Meter Number "),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((benefit.value.meter_no) = $event)),
                          type: "text",
                          class: "form-control border-gray bg-gray mb-2",
                          placeholder: "e.g 123456789"
                        }, null, 512), [
                          [_vModelText, benefit.value.meter_no]
                        ])
                      ]),
                      (errors.value.meter_no)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_49, _toDisplayString(eMsg.value.meter_no), 1))
                        : _createCommentVNode("", true),
                      _cache[37] || (_cache[37] = _createElementVNode("div", null, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_50, [
                      _cache[40] || (_cache[40] = _createElementVNode("label", {
                        for: "meter",
                        class: "mb-2"
                      }, [
                        _createTextVNode("Provider "),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", _hoisted_51, [
                        _createElementVNode("span", _hoisted_52, [
                          _createVNode(_component_Iconify, {
                            icon: "carbon:location",
                            class: "pe-2",
                            style: {"width":"30px","height":"30px","color":"#818181","border-right":"1px solid #c6c6c6"}
                          })
                        ]),
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((benefit.value.provider) = $event)),
                          class: "form-select form-select-lg mb-3",
                          "aria-label": "Large select example"
                        }, [
                          _cache[38] || (_cache[38] = _createElementVNode("option", {
                            disabled: "",
                            value: ""
                          }, "select option", -1)),
                          _cache[39] || (_cache[39] = _createTextVNode(" = ")),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providers.value, (provider, index) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: index,
                              value: provider.serviceID
                            }, _toDisplayString(provider.serviceID), 9, _hoisted_53))
                          }), 128))
                        ], 512), [
                          [_vModelSelect, benefit.value.provider]
                        ])
                      ]),
                      (errors.value.provider)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_54, _toDisplayString(eMsg.value.provider), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_55, [
                      _cache[42] || (_cache[42] = _createElementVNode("label", {
                        class: "mb-2",
                        for: "meter"
                      }, [
                        _createTextVNode("Meter Type "),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((benefit.value.meter_type) = $event)),
                          class: "form-select form-select-lg mb-3",
                          "aria-label": "Large select example"
                        }, _cache[41] || (_cache[41] = [
                          _createElementVNode("option", {
                            disabled: "",
                            value: ""
                          }, "select option", -1),
                          _createElementVNode("option", { value: "prepaid" }, "prepaid", -1),
                          _createElementVNode("option", { value: "postpaid" }, "postpaid", -1)
                        ]), 512), [
                          [_vModelSelect, benefit.value.meter_type]
                        ])
                      ]),
                      (errors.value.meter_type)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_56, _toDisplayString(eMsg.value.meter_type), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_57, [
                      _cache[44] || (_cache[44] = _createElementVNode("label", {
                        for: "meter",
                        class: "mb-2"
                      }, [
                        _createTextVNode("Phone Number "),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", _hoisted_58, [
                        _createElementVNode("div", _hoisted_59, [
                          _createVNode(_component_Iconify, {
                            icon: "twemoji:flag-nigeria",
                            style: {"width":"24px","height":"24x"}
                          }),
                          _cache[43] || (_cache[43] = _createElementVNode("span", {
                            class: "pe-2",
                            style: {"border-right":"1px solid gray"}
                          }, " +234", -1))
                        ]),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((benefit.value.phone) = $event)),
                          type: "text",
                          class: "form-control border-gray bg-gray",
                          placeholder: "081*******",
                          "aria-label": "Username",
                          "aria-describedby": "basic-addon1"
                        }, null, 512), [
                          [_vModelText, benefit.value.phone]
                        ])
                      ]),
                      (errors.value.phone)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_60, _toDisplayString(eMsg.value.phone), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_61, [
                      _cache[45] || (_cache[45] = _createElementVNode("label", {
                        class: "mb-2",
                        for: "meter"
                      }, "Email Address ", -1)),
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((benefit.value.email) = $event)),
                          type: "text",
                          class: "form-control border-gray bg-gray mb-2",
                          placeholder: "e.g jojndoe@gmail.com"
                        }, null, 512), [
                          [_vModelText, benefit.value.email]
                        ])
                      ]),
                      (errors.value.email)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_62, _toDisplayString(eMsg.value.email), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_63, [
                      _createElementVNode("button", {
                        type: "submit",
                        class: "border-gray rounded btn-primary text-white",
                        style: {"height":"44px"},
                        disabled: loading.value
                      }, _toDisplayString(loading.value ? "Loading..." : " Verify"), 9, _hoisted_64)
                    ])
                  ], 32)
                ]))
              : _createCommentVNode("", true),
            (showSummary.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                  _createElementVNode("div", _hoisted_66, [
                    _createElementVNode("div", {
                      class: "back-icon me-3",
                      onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_unref(router).go(-1)), ["prevent"]))
                    }, [
                      _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
                    ]),
                    _cache[46] || (_cache[46] = _createElementVNode("p", null, "Verified Details", -1))
                  ]),
                  _createElementVNode("div", _hoisted_67, [
                    _createElementVNode("div", _hoisted_68, [
                      _cache[47] || (_cache[47] = _createElementVNode("p", { class: "text-gray p-text" }, "Provider", -1)),
                      _createElementVNode("div", _hoisted_69, _toDisplayString(benefit.value.provider), 1)
                    ]),
                    _createElementVNode("div", _hoisted_70, [
                      _cache[48] || (_cache[48] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Type", -1)),
                      _createElementVNode("p", _hoisted_71, _toDisplayString(benefit.value.meter_type), 1)
                    ]),
                    _createElementVNode("div", _hoisted_72, [
                      _cache[49] || (_cache[49] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Number", -1)),
                      _createElementVNode("p", _hoisted_73, _toDisplayString(benefit.value.meter_no), 1)
                    ]),
                    _createElementVNode("div", _hoisted_74, [
                      _cache[50] || (_cache[50] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Name", -1)),
                      _createElementVNode("p", _hoisted_75, _toDisplayString(apiResponse.Customer_Name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_76, [
                      _cache[51] || (_cache[51] = _createElementVNode("p", { class: "text-gray p-text" }, "Phone Number", -1)),
                      _createElementVNode("p", _hoisted_77, _toDisplayString(benefit.value.phone), 1)
                    ]),
                    _createElementVNode("div", _hoisted_78, [
                      _cache[52] || (_cache[52] = _createElementVNode("p", { class: "text-gray p-text" }, "Email", -1)),
                      _createElementVNode("p", _hoisted_79, _toDisplayString(benefit.value.email), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_80, [
                    _createElementVNode("button", {
                      onClick: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (saveBeneficiary()), ["prevent"])),
                      type: "submit",
                      class: "border-gray rounded btn-primary text-white",
                      style: {"height":"44px"},
                      disabled: loading.value
                    }, _toDisplayString(loading.value ? "Loading..." : " Save Beneficiary"), 9, _hoisted_81)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})