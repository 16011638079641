import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelDynamic as _vModelDynamic, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "offcanvas offcanvas-bottom rounded-top half-size",
  tabindex: "-1",
  id: "offcanvasBottomA",
  "aria-labelledby": "offcanvasBottomLabelA"
}
const _hoisted_2 = { class: "offcanvas-body" }
const _hoisted_3 = { class: "each-field" }
const _hoisted_4 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_5 = { class: "d-grid mt-3" }
const _hoisted_6 = { class: "d-flex gap-2 rounded justify-content-center align-items-center" }
const _hoisted_7 = { class: "d-grid" }
const _hoisted_8 = { class: "d-flex align-items-center btn-normal" }
const _hoisted_9 = { class: "d-grid" }
const _hoisted_10 = {
  class: "offcanvas offcanvas-bottom rounded-top full-size",
  tabindex: "-1",
  id: "offcanvasBottom2",
  "aria-labelledby": "offcanvasBottomLabel2"
}
const _hoisted_11 = { class: "offcanvas-header border-bottom" }
const _hoisted_12 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_13 = {
  type: "button",
  class: "text-reset back-icon",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_14 = { class: "offcanvas-body" }
const _hoisted_15 = { class: "each-field mb-3" }
const _hoisted_16 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_17 = { class: "each-field mb-3" }
const _hoisted_18 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_19 = { class: "each-field" }
const _hoisted_20 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_21 = { class: "each-field mb-3" }
const _hoisted_22 = { class: "input-group mb-3" }
const _hoisted_23 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_24 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_25 = { class: "col-12" }
const _hoisted_26 = { class: "each-field" }
const _hoisted_27 = { class: "password-container" }
const _hoisted_28 = ["type"]
const _hoisted_29 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_30 = { class: "d-grid mt-4" }
const _hoisted_31 = ["disabled"]
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "mb-3 d-flex gap-2 rounded justify-content-center align-items-center" }
const _hoisted_35 = { class: "d-grid" }
const _hoisted_36 = { class: "d-flex align-items-center btn-normal" }
const _hoisted_37 = { class: "d-grid" }
const _hoisted_38 = { class: "btn-normal d-flex align-items-center" }
const _hoisted_39 = {
  class: "offcanvas offcanvas-bottom rounded-top medium-size",
  tabindex: "-1",
  id: "offcanvasBottom3",
  "aria-labelledby": "offcanvasBottomLabel3"
}
const _hoisted_40 = { class: "offcanvas-body" }
const _hoisted_41 = { class: "each-field mb-3" }
const _hoisted_42 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_43 = { class: "each-field" }
const _hoisted_44 = { class: "password-container" }
const _hoisted_45 = ["type"]
const _hoisted_46 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_47 = { class: "d-grid mt-4" }
const _hoisted_48 = { class: "d-grid" }

import { ref, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'CreateAccountMOB',
  props: {
  purchase: Object,
},
  emits: ["continue", "guestPurchase"],
  setup(__props, { emit: __emit }) {

const store = useStore();
const $toast = useToast();

const register = reactive({
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  phone: "",
});

const errors = ref({
  email: false,
  password: false,
  phone: false,
  first_name: false,
  last_name: false,
});
const eMsg = ref({
  email: "This field is required",
  password: "This field is required",
  phone: "This field is required",
  first_name: "This field is required",
  last_name: "This field is required",
});


const passwordVisible = ref(false);
const loading = ref(false);
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;

const guestPurchase = () => {
  emit("guestPurchase", props.purchase);
};
const emit = __emit;
const props = __props;

const handleContinue = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (register.first_name == "") {
    errors.value.first_name = true;
    return;
  } else {
    errors.value.first_name = false;
  }
  if (register.last_name == "") {
    errors.value.last_name = true;
    return;
  } else {
    errors.value.last_name = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(register.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }

  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/auth/register",
      details: {
        email: register.email,
        first_name: register.first_name,
        last_name: register.last_name,
        password: register.password,
        phone: register.phone,
      },
    })
    .then((resp) => {
      loading.value = false;
      // console.log(resp);
      store.commit("setUser", resp.data);
      useToast().success("Account created successfully");
  emit("continue");

    });
}

const handleNextPage = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  const offcanvasBottom = document.getElementById("offcanvasBottomA");
  const secondoffcanvas = document.getElementById("offcanvasBottom2");
  // window.bootstrap
  var bsOffcanvas = window.bootstrap.Offcanvas.getInstance(offcanvasBottom);
  bsOffcanvas.hide();

  var bsOffcanvas2 = new window.bootstrap.Offcanvas(secondoffcanvas);
  bsOffcanvas2.show();
};

const Login = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (register.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }
  loading.value = true;
  store
    .dispatch("post", {
      endpoint: "/auth/login",
      details: { email: register.email, password: register.password },
    })
    .then((resp) => {
      loading.value = false;
      // console.log(resp);
      store.commit("setUser", resp.data);
      useToast().success("Login Successful");
      window.location.href = "/buy-electricity";
    })
    .catch(() => {
      loading.value = false;
    });
};



const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "offcanvas-header border-bottom" }, [
        _createElementVNode("h5", {
          class: "offcanvas-title text-center",
          id: "offcanvasBottomLabelA"
        }, " Create an account to pay ")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[18] || (_cache[18] = _createElementVNode("p", { class: "text-gray my-2 fs-14" }, " Enter your details to create an account with your email address ", -1)),
        _createElementVNode("div", _hoisted_3, [
          _cache[16] || (_cache[16] = _createElementVNode("label", {
            class: "mb-2 fs-6",
            for: "email"
          }, [
            _createTextVNode("Email Address "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((register.email) = $event)),
              type: "email",
              class: "form-control border-gray bg-gray",
              placeholder: "e.g joedoe@gmail.com"
            }, null, 512), [
              [_vModelText, register.email]
            ])
          ]),
          (errors.value.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(eMsg.value.email), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleNextPage()), ["prevent"])),
              class: "p-2 border-gray rounded btn-primary fw-bold mb-2",
              "data-bs-toggle": "offcanvas",
              "data-bs-target": "#offcanvasBottom2",
              "aria-controls": "offcanvasBottom2"
            }, " Continue "),
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-center" }, [
              _createElementVNode("p", { class: "text-gray fs-14" }, [
                _createTextVNode(" Have an account already? "),
                _createElementVNode("span", {
                  class: "text-primary fs-14",
                  "data-bs-toggle": "offcanvas",
                  "data-bs-target": "#offcanvasBottom3",
                  "aria-controls": "offcanvasBottom3"
                }, " Sign In")
              ])
            ], -1))
          ]),
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "d-flex justify-content-center align-items-center my-3" }, [
            _createElementVNode("div", { class: "line" }),
            _createElementVNode("div", { class: "text-gray mx-1" }, "Or"),
            _createElementVNode("div", { class: "line" })
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", _hoisted_8, [
                _createVNode(_component_Iconify, {
                  icon: "flat-color-icons:google",
                  style: {"font-size":"20px"}
                }),
                _cache[14] || (_cache[14] = _createElementVNode("span", { class: "ms-1" }, "Continue with Google", -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (guestPurchase()), ["prevent"])),
                class: "btn-normal d-flex align-items-center"
              }, [
                _createVNode(_component_Iconify, {
                  class: "primary",
                  icon: "solar:user-bold",
                  style: {"font-size":"24px"}
                }),
                _cache[15] || (_cache[15] = _createElementVNode("span", { class: "ms-1" }, "Continue as a guest", -1))
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("button", _hoisted_13, [
            _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
          ]),
          _cache[20] || (_cache[20] = _createElementVNode("div", null, [
            _createElementVNode("h5", {
              class: "offcanvas-title text-center ms-4",
              id: "offcanvasBottomLabel2"
            }, " Create an account to pay ")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[29] || (_cache[29] = _createElementVNode("p", { class: "text-gray my-4" }, " Enter your details to create an account with your email address ", -1)),
        _createElementVNode("div", _hoisted_15, [
          _cache[21] || (_cache[21] = _createElementVNode("label", {
            class: "mb-2 fs-6",
            for: "email"
          }, [
            _createTextVNode("Email Address "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((register.email) = $event)),
              type: "email",
              class: "form-control border-0 bg-gray",
              placeholder: "e.g joedoe@gmail.com"
            }, null, 512), [
              [_vModelText, register.email]
            ])
          ]),
          (errors.value.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(eMsg.value.email), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _cache[22] || (_cache[22] = _createElementVNode("label", {
            class: "mb-2",
            for: "email"
          }, [
            _createTextVNode("First Name "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((register.first_name) = $event)),
              type: "email",
              class: "form-control border-0 bg-gray",
              placeholder: "e.g joe"
            }, null, 512), [
              [_vModelText, register.first_name]
            ])
          ]),
          (errors.value.first_name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(eMsg.value.first_name), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _cache[23] || (_cache[23] = _createElementVNode("label", {
            class: "mb-2",
            for: "email"
          }, [
            _createTextVNode("Last Name "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((register.last_name) = $event)),
              type: "text",
              class: "form-control border-0 bg-gray",
              placeholder: "e.g doe"
            }, null, 512), [
              [_vModelText, register.last_name]
            ])
          ]),
          (errors.value.last_name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(eMsg.value.last_name), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _cache[25] || (_cache[25] = _createElementVNode("label", {
            for: "meter",
            class: "mb-2"
          }, [
            _createTextVNode("Phone Number "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_Iconify, {
                icon: "twemoji:flag-nigeria",
                style: {"width":"24px","height":"24x"}
              }),
              _cache[24] || (_cache[24] = _createElementVNode("span", {
                class: "pe-2",
                style: {"border-right":"1px solid gray"}
              }, " +234", -1))
            ]),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((register.phone) = $event)),
              type: "tel",
              class: "form-control border-gray bg-gray",
              placeholder: "081*******",
              "aria-label": "Username",
              "aria-describedby": "basic-addon1"
            }, null, 512), [
              [_vModelText, register.phone]
            ])
          ]),
          (errors.value.phone)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(eMsg.value.phone), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _cache[26] || (_cache[26] = _createElementVNode("label", {
              class: "mb-2",
              for: "email"
            }, [
              _createTextVNode("Password "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", _hoisted_27, [
              _withDirectives(_createElementVNode("input", {
                type: passwordVisible.value ? 'text' : 'password',
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((register.password) = $event)),
                class: "form-control border-gray bg-gray",
                placeholder: "......"
              }, null, 8, _hoisted_28), [
                [_vModelDynamic, register.password]
              ]),
              _createElementVNode("span", { onClick: togglePasswordVisibility }, [
                _createVNode(_component_Iconify, {
                  icon: 
                  passwordVisible.value ? 'solar:eye-outline' : 'ph:eye-slash-duotone'
                ,
                  class: "toggle-eye"
                }, null, 8, ["icon"])
              ])
            ]),
            (errors.value.password)
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(eMsg.value.password), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _cache[30] || (_cache[30] = _createElementVNode("p", { class: "text-gray" }, "Password must contain", -1)),
        _cache[31] || (_cache[31] = _createElementVNode("span", { class: "text-gray" }, " •   6-20 characters ", -1)),
        _cache[32] || (_cache[32] = _createElementVNode("span", { class: "text-gray" }, " •   Including numbers, letters and special character (!&*/?..) ", -1)),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("button", {
            onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (handleContinue()), ["prevent"])),
            class: "p-2 border-gray rounded btn-primary fw-bold mb-2",
            disabled: loading.value
          }, [
            (loading.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_32, "Loading..."))
              : (_openBlock(), _createElementBlock("span", _hoisted_33, "Create an account"))
          ], 8, _hoisted_31)
        ]),
        _cache[33] || (_cache[33] = _createStaticVNode("<div class=\"text-center\"><p class=\"text-gray\"> Have an account already? <span class=\"text-primary\" data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom3\" aria-controls=\"offcanvasBottom3\"> Sign In</span></p></div><div class=\"d-flex justify-content-center align-items-center my-4\"><div class=\"line\"></div><div class=\"text-gray mx-1\">Or</div><div class=\"line\"></div></div>", 2)),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("button", _hoisted_36, [
              _createVNode(_component_Iconify, {
                icon: "flat-color-icons:google",
                style: {"font-size":"20px"}
              }),
              _cache[27] || (_cache[27] = _createElementVNode("span", { class: "ms-1" }, "Continue with Google", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("button", _hoisted_38, [
              _createVNode(_component_Iconify, {
                class: "primary",
                icon: "solar:user-bold",
                style: {"font-size":"24px"}
              }),
              _cache[28] || (_cache[28] = _createElementVNode("span", { class: "ms-1" }, "Continue as a guest", -1))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_39, [
      _cache[39] || (_cache[39] = _createElementVNode("div", { class: "offcanvas-header border-bottom" }, [
        _createElementVNode("div", { class: "d-flex justify-content-center align-items-center" }, [
          _createElementVNode("h5", {
            class: "offcanvas-title text-center ms-4",
            id: "offcanvasBottomLabel3"
          }, " Sign In ")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_40, [
        _cache[37] || (_cache[37] = _createElementVNode("p", { class: "text-gray my-4 fs-14" }, " Enter your registered details to continue ", -1)),
        _createElementVNode("div", _hoisted_41, [
          _cache[34] || (_cache[34] = _createElementVNode("label", {
            class: "mb-2 fs-6",
            for: "email"
          }, [
            _createTextVNode("Email Address "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((register.email) = $event)),
              type: "email",
              class: "form-control border-gray bg-gray",
              placeholder: "e.g joedoe@gmail.com"
            }, null, 512), [
              [_vModelText, register.email]
            ])
          ]),
          (errors.value.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(eMsg.value.email), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_43, [
          _cache[35] || (_cache[35] = _createElementVNode("label", {
            class: "mb-2",
            for: "email"
          }, [
            _createTextVNode("Password "),
            _createElementVNode("span", { class: "text-danger" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_44, [
            _withDirectives(_createElementVNode("input", {
              type: passwordVisible.value ? 'text' : 'password',
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((register.password) = $event)),
              class: "form-control border-gray bg-gray",
              placeholder: "......"
            }, null, 8, _hoisted_45), [
              [_vModelDynamic, register.password]
            ]),
            _createElementVNode("span", { onClick: togglePasswordVisibility }, [
              _createVNode(_component_Iconify, {
                icon: 
                  passwordVisible.value ? 'solar:eye-outline' : 'ph:eye-slash-duotone'
                ,
                class: "toggle-eye"
              }, null, 8, ["icon"])
            ])
          ]),
          (errors.value.password)
            ? (_openBlock(), _createElementBlock("div", _hoisted_46, _toDisplayString(eMsg.value.password), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_47, [
          _createElementVNode("button", {
            onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (Login()), ["prevent"])),
            class: "p-2 border-gray rounded btn-primary fw-bold mb-2"
          }, " Continue ")
        ]),
        _cache[38] || (_cache[38] = _createStaticVNode("<div class=\"text-center\"><p class=\"text-gray fs-14\"> Don&#39;t have an account? <span class=\"text-primary fs-14\" data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom2\" aria-controls=\"offcanvasBottom2\"> Sign Up</span></p></div><div class=\"d-flex justify-content-center align-items-center my-4\"><div class=\"line\"></div><div class=\"text-gray mx-1\">Or</div><div class=\"line\"></div></div>", 2)),
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("button", {
            onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (guestPurchase()), ["prevent"])),
            class: "btn-normal justify-content-center d-flex align-items-center"
          }, [
            _createVNode(_component_Iconify, {
              class: "primary",
              icon: "solar:user-bold",
              style: {"font-size":"24px"}
            }),
            _cache[36] || (_cache[36] = _createElementVNode("span", { class: "ms-1" }, "Continue as a guest", -1))
          ])
        ])
      ])
    ])
  ], 64))
}
}

})