import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vModelDynamic as _vModelDynamic } from "vue"

const _hoisted_1 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_2 = { class: "modal-content p-4" }
const _hoisted_3 = { class: "modal-header border-0 p-0" }
const _hoisted_4 = {
  type: "button",
  "data-bs-dismiss": "modal",
  "aria-label": "Close",
  style: {"height":"27px","width":"27px","border-radius":"50%","background-color":"black","color":"white"}
}
const _hoisted_5 = { class: "modal-body pt-3 px-0" }
const _hoisted_6 = { class: "each-field" }
const _hoisted_7 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_8 = { class: "modal-footer border-0 p-0" }
const _hoisted_9 = { class: "d-grid m-0" }
const _hoisted_10 = { class: "d-flex gap-4 rounded justify-content-center align-items-center" }
const _hoisted_11 = { class: "d-grid" }
const _hoisted_12 = { class: "d-flex align-items-center btn-normal" }
const _hoisted_13 = { class: "d-grid" }
const _hoisted_14 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_15 = { class: "modal-content p-4" }
const _hoisted_16 = { class: "modal-header border-0 p-0" }
const _hoisted_17 = {
  type: "button",
  "data-bs-dismiss": "modal",
  "aria-label": "Close",
  style: {"height":"27px","width":"27px","border-radius":"50%","background-color":"black","color":"white"}
}
const _hoisted_18 = { class: "modal-body pt-3 px-0" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-6" }
const _hoisted_21 = { class: "each-field" }
const _hoisted_22 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_23 = { class: "col-6" }
const _hoisted_24 = { class: "each-field" }
const _hoisted_25 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_26 = { class: "col-6 mt-4" }
const _hoisted_27 = { class: "each-field" }
const _hoisted_28 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_29 = { class: "col-6 mt-4" }
const _hoisted_30 = { class: "each-field mb-3" }
const _hoisted_31 = { class: "input-group mb-3" }
const _hoisted_32 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_33 = {
  key: 0,
  class: "error-msg text-danger ms-2",
  style: {"font-size":"12px"}
}
const _hoisted_34 = { class: "col-12" }
const _hoisted_35 = { class: "each-field" }
const _hoisted_36 = { class: "password-container" }
const _hoisted_37 = ["type"]
const _hoisted_38 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_39 = { class: "modal-footer border-0 p-0" }
const _hoisted_40 = { class: "d-grid m-0" }
const _hoisted_41 = ["disabled"]
const _hoisted_42 = { key: 0 }
const _hoisted_43 = { key: 1 }
const _hoisted_44 = { class: "d-flex gap-4 rounded justify-content-center align-items-center" }
const _hoisted_45 = { class: "d-grid" }
const _hoisted_46 = { class: "d-flex align-items-center btn-normal" }
const _hoisted_47 = { class: "d-grid" }
const _hoisted_48 = {
  class: "modal fade",
  id: "exampleModalToggle3",
  "aria-hidden": "true",
  "aria-labelledby": "exampleModalToggleLabel3",
  tabindex: "-1"
}
const _hoisted_49 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_50 = { class: "modal-content p-4" }
const _hoisted_51 = { class: "modal-header border-0 p-0" }
const _hoisted_52 = {
  type: "button",
  "data-bs-dismiss": "modal",
  "aria-label": "Close",
  style: {"height":"27px","width":"27px","border-radius":"50%","background-color":"black","color":"white"}
}
const _hoisted_53 = { class: "modal-body pt-3 px-0" }
const _hoisted_54 = { class: "row" }
const _hoisted_55 = { class: "col-12" }
const _hoisted_56 = { class: "each-field" }
const _hoisted_57 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_58 = { class: "col-12" }
const _hoisted_59 = { class: "each-field" }
const _hoisted_60 = { class: "password-container" }
const _hoisted_61 = ["type"]
const _hoisted_62 = {
  key: 0,
  class: "error-msg text-danger ms-2 mb-3",
  style: {"font-size":"12px"}
}
const _hoisted_63 = { class: "modal-footer border-0 p-0" }
const _hoisted_64 = { class: "d-grid m-0" }
const _hoisted_65 = ["disabled"]
const _hoisted_66 = { class: "d-grid" }

import { ref, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateAccount',
  props: {
  purchase: Object
},
  emits: ["continue", 'guestPurchase'],
  setup(__props, { emit: __emit }) {


const store = useStore();
const $toast = useToast();
const emit = __emit;
const props = __props;

const passwordVisible = ref(false);
const loading = ref(false);
const firstModal = ref(null);
const secondModal = ref(null);
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;


const guestPurchase = () => {
  emit('guestPurchase', props.purchase);
};

const register = reactive({
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  phone: "",
});

const errors = ref({
  email: false,
  password: false,
  phone: false,
  first_name: false,
  last_name: false,
});
const eMsg = ref({
  email: "This field is required",
  password: "This field is required",
  phone: "This field is required",
  first_name: "This field is required",
  last_name: "This field is required",
});

const handleNextPage = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;

    hideFirstModal();
    showSecondModal();
  }
};
const hideFirstModal = () => {
  const modal = bootstrap.Modal.getInstance(firstModal.value);
  modal.hide();
};

const showSecondModal = () => {
  const modal = new bootstrap.Modal(secondModal.value);
  modal.show();
};

const handleContinue = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (register.first_name == "") {
    errors.value.first_name = true;
    return;
  } else {
    errors.value.first_name = false;
  }
  if (register.last_name == "") {
    errors.value.last_name = true;
    return;
  } else {
    errors.value.last_name = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(register.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }

  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/auth/register",
      details: {
        email: register.email,
        first_name: register.first_name,
        last_name: register.last_name,
        password: register.password,
        phone: register.phone,
      },
    })
    .then((resp) => {
      loading.value = false;
      console.log(resp);
      store.commit("setUser", resp.data);
      useToast().success("Account created successfully");
      var ad = document.getElementById("exampleModalToggle2");
      var md = window.bootstrap.Modal.getInstance(ad);
      md.hide();
      emit("continue");
    })
    .catch(() => {
      loading.value = false;
    });
};

const Login = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (register.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }
  loading.value = true;
  // console.log("Success bukky");
  store
    .dispatch("post", {
      endpoint: "/auth/login",
      details: { email: register.email, password: register.password },
    })
    .then((resp) => {
      loading.value = false;
      console.log(resp);
      store.commit("setUser", resp.data);
      useToast().success("Login Successful");
      window.location.href = "/buy-electricity";
    })
    .catch(() => {
      loading.value = false;
    });
};

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};


return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "modal fade",
      id: "exampleModalToggle",
      "aria-hidden": "true",
      "aria-labelledby": "exampleModalToggleLabel",
      tabindex: "-1",
      ref_key: "firstModal",
      ref: firstModal
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[14] || (_cache[14] = _createElementVNode("div", null, [
              _createElementVNode("h1", {
                class: "modal-title fs-5",
                id: "exampleModalToggleLabel"
              }, " Create an account to pay ")
            ], -1)),
            _createElementVNode("button", _hoisted_4, [
              _createVNode(_component_Iconify, {
                icon: "iconamoon:close-duotone",
                style: {"font-size":"18px"}
              })
            ])
          ]),
          _cache[20] || (_cache[20] = _createElementVNode("span", { class: "text-gray" }, " Enter your details to create an account with your email address", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[15] || (_cache[15] = _createElementVNode("label", {
                class: "mb-2",
                for: "email"
              }, [
                _createTextVNode("Email Address "),
                _createElementVNode("span", { class: "text-danger" }, "*")
              ], -1)),
              _createElementVNode("div", null, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((register.email) = $event)),
                  type: "email",
                  class: "form-control border-0 bg-gray",
                  placeholder: "e.g joedoe@gmail.com"
                }, null, 512), [
                  [_vModelText, register.email]
                ])
              ]),
              (errors.value.email)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(eMsg.value.email), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleNextPage()), ["prevent"])),
                class: "p-2 border-0 rounded disabled fw-bold mb-2"
              }, " Continue "),
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-center" }, [
                _createElementVNode("p", { class: "text-gray" }, [
                  _createTextVNode(" Have an account already? "),
                  _createElementVNode("span", {
                    "data-bs-target": "#exampleModalToggle3",
                    "data-bs-toggle": "modal",
                    class: "text-primary"
                  }, " Sign In")
                ])
              ], -1))
            ]),
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "d-flex justify-content-center align-items-center my-3" }, [
              _createElementVNode("div", { class: "line" }),
              _createElementVNode("div", { class: "text-gray mx-1" }, "Or"),
              _createElementVNode("div", { class: "line" })
            ], -1)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", _hoisted_12, [
                  _createVNode(_component_Iconify, {
                    icon: "flat-color-icons:google",
                    style: {"font-size":"20px"}
                  }),
                  _cache[17] || (_cache[17] = _createElementVNode("span", { class: "ms-1" }, "Continue with Google", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (guestPurchase()), ["prevent"])),
                  class: "btn-normal d-flex align-items-center"
                }, [
                  _createVNode(_component_Iconify, {
                    class: "primary",
                    icon: "solar:user-bold",
                    style: {"font-size":"24px"}
                  }),
                  _cache[18] || (_cache[18] = _createElementVNode("span", { class: "ms-1" }, "Continue as a guest", -1))
                ])
              ])
            ])
          ])
        ])
      ])
    ], 512),
    _createElementVNode("div", {
      class: "modal fade",
      id: "exampleModalToggle2",
      "aria-hidden": "true",
      "aria-labelledby": "exampleModalToggleLabel2",
      tabindex: "-1",
      ref_key: "secondModal",
      ref: secondModal
    }, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _cache[21] || (_cache[21] = _createElementVNode("div", null, [
              _createElementVNode("h1", {
                class: "modal-title fs-5",
                id: "exampleModalToggleLabel2"
              }, " Create an account ")
            ], -1)),
            _createElementVNode("button", _hoisted_17, [
              _createVNode(_component_Iconify, {
                icon: "iconamoon:close-duotone",
                style: {"font-size":"18px"}
              })
            ])
          ]),
          _cache[35] || (_cache[35] = _createElementVNode("span", { class: "text-gray" }, " Enter your details to create an account with your email address", -1)),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _cache[22] || (_cache[22] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "email"
                  }, [
                    _createTextVNode("Email Address "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((register.email) = $event)),
                      type: "text",
                      class: "form-control border-0 bg-gray",
                      placeholder: "e.g joedoe@gmail.com"
                    }, null, 512), [
                      [_vModelText, register.email]
                    ])
                  ]),
                  (errors.value.email)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(eMsg.value.email), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _cache[23] || (_cache[23] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "email"
                  }, [
                    _createTextVNode("First Name "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((register.first_name) = $event)),
                      type: "email",
                      class: "form-control border-0 bg-gray",
                      placeholder: "e.g joe"
                    }, null, 512), [
                      [_vModelText, register.first_name]
                    ])
                  ]),
                  (errors.value.first_name)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(eMsg.value.first_name), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _cache[24] || (_cache[24] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "email"
                  }, [
                    _createTextVNode("Last Name "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((register.last_name) = $event)),
                      type: "text",
                      class: "form-control border-0 bg-gray",
                      placeholder: "e.g doe"
                    }, null, 512), [
                      [_vModelText, register.last_name]
                    ])
                  ]),
                  (errors.value.last_name)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(eMsg.value.last_name), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _cache[26] || (_cache[26] = _createElementVNode("label", {
                    for: "meter",
                    class: "mb-2"
                  }, [
                    _createTextVNode("Phone Number "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, [
                        _createVNode(_component_Iconify, {
                          icon: "twemoji:flag-nigeria",
                          style: {"width":"24px","height":"24x"}
                        }),
                        _cache[25] || (_cache[25] = _createElementVNode("span", {
                          class: "pe-2",
                          style: {"border-right":"1px solid gray"}
                        }, " +234", -1))
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((register.phone) = $event)),
                        type: "tel",
                        class: "form-control border-0 bg-gray",
                        placeholder: "081*******",
                        "aria-label": "Username",
                        "aria-describedby": "basic-addon1"
                      }, null, 512), [
                        [_vModelText, register.phone]
                      ])
                    ]),
                    (errors.value.phone)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(eMsg.value.phone), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _cache[27] || (_cache[27] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "email"
                  }, [
                    _createTextVNode("Password "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_36, [
                    _withDirectives(_createElementVNode("input", {
                      type: passwordVisible.value ? 'text' : 'password',
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((register.password) = $event)),
                      class: "form-control border-0 bg-gray",
                      placeholder: "......"
                    }, null, 8, _hoisted_37), [
                      [_vModelDynamic, register.password]
                    ]),
                    _createElementVNode("span", { onClick: togglePasswordVisibility }, [
                      _createVNode(_component_Iconify, {
                        icon: 
                          passwordVisible.value
                            ? 'solar:eye-outline'
                            : 'ph:eye-slash-duotone'
                        ,
                        class: "toggle-eye"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  (errors.value.password)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_38, _toDisplayString(eMsg.value.password), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _cache[28] || (_cache[28] = _createElementVNode("p", { class: "text-gray" }, "Password must contain", -1)),
              _cache[29] || (_cache[29] = _createElementVNode("span", { class: "text-gray" }, " •   8-20 characters ", -1)),
              _cache[30] || (_cache[30] = _createElementVNode("span", { class: "text-gray" }, " •   Including numbers, letters and special character (!&*/?..) ", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("button", {
                onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (handleContinue()), ["prevent"])),
                class: "p-2 border-0 rounded disabled fw-bold mb-2",
                disabled: loading.value
              }, [
                (loading.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_42, "Loading..."))
                  : (_openBlock(), _createElementBlock("span", _hoisted_43, "Create an account"))
              ], 8, _hoisted_41),
              _cache[31] || (_cache[31] = _createElementVNode("div", { class: "text-center" }, [
                _createElementVNode("p", { class: "text-gray" }, [
                  _createTextVNode(" Have an account already? "),
                  _createElementVNode("span", {
                    "data-bs-target": "#exampleModalToggle3",
                    "data-bs-toggle": "modal",
                    class: "text-primary"
                  }, " Sign in")
                ])
              ], -1))
            ]),
            _cache[34] || (_cache[34] = _createElementVNode("div", { class: "d-flex justify-content-center align-items-center my-3" }, [
              _createElementVNode("div", { class: "line" }),
              _createElementVNode("div", { class: "text-gray mx-1" }, "Or"),
              _createElementVNode("div", { class: "line" })
            ], -1)),
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("button", _hoisted_46, [
                  _createVNode(_component_Iconify, {
                    icon: "flat-color-icons:google",
                    style: {"font-size":"20px"}
                  }),
                  _cache[32] || (_cache[32] = _createElementVNode("span", { class: "ms-1" }, "Continue with Google", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("button", {
                  onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (guestPurchase()), ["prevent"])),
                  class: "btn-normal d-flex align-items-center"
                }, [
                  _createVNode(_component_Iconify, {
                    class: "primary",
                    icon: "solar:user-bold",
                    style: {"font-size":"24px"}
                  }),
                  _cache[33] || (_cache[33] = _createElementVNode("span", { class: "ms-1" }, "Continue as a guest", -1))
                ])
              ])
            ])
          ])
        ])
      ])
    ], 512),
    _createElementVNode("div", _hoisted_48, [
      _createElementVNode("div", _hoisted_49, [
        _createElementVNode("div", _hoisted_50, [
          _createElementVNode("div", _hoisted_51, [
            _cache[36] || (_cache[36] = _createElementVNode("div", null, [
              _createElementVNode("h1", {
                class: "modal-title fs-5",
                id: "exampleModalToggleLabel3"
              }, " Sign In ")
            ], -1)),
            _createElementVNode("button", _hoisted_52, [
              _createVNode(_component_Iconify, {
                icon: "iconamoon:close-duotone",
                style: {"font-size":"18px"}
              })
            ])
          ]),
          _cache[42] || (_cache[42] = _createElementVNode("span", { class: "text-gray" }, " Enter your registered details to continue ", -1)),
          _createElementVNode("div", _hoisted_53, [
            _createElementVNode("div", _hoisted_54, [
              _createElementVNode("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _cache[37] || (_cache[37] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "email"
                  }, [
                    _createTextVNode("Email Address "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((register.email) = $event)),
                      type: "text",
                      class: "form-control border-0 bg-gray",
                      placeholder: "e.g joedoe@gmail.com"
                    }, null, 512), [
                      [_vModelText, register.email]
                    ])
                  ]),
                  (errors.value.email)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_57, _toDisplayString(eMsg.value.email), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_58, [
                _createElementVNode("div", _hoisted_59, [
                  _cache[38] || (_cache[38] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "email"
                  }, [
                    _createTextVNode("Password "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_60, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((register.password) = $event)),
                      type: passwordVisible.value ? 'text' : 'password',
                      class: "form-control border-0 bg-gray",
                      placeholder: "......"
                    }, null, 8, _hoisted_61), [
                      [_vModelDynamic, register.password]
                    ]),
                    _createElementVNode("span", { onClick: togglePasswordVisibility }, [
                      _createVNode(_component_Iconify, {
                        icon: 
                          passwordVisible.value
                            ? 'solar:eye-outline'
                            : 'ph:eye-slash-duotone'
                        ,
                        class: "toggle-eye"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  (errors.value.password)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_62, _toDisplayString(eMsg.value.password), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_63, [
            _createElementVNode("div", _hoisted_64, [
              _createElementVNode("button", {
                disabled: loading.value,
                onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (Login()), ["prevent"])),
                class: "p-2 border-0 rounded disabled fw-bold mb-2"
              }, _toDisplayString(loading.value ? "Loading..." : "Continue"), 9, _hoisted_65),
              _cache[39] || (_cache[39] = _createElementVNode("div", { class: "text-center" }, [
                _createElementVNode("p", { class: "text-gray" }, [
                  _createTextVNode(" Don't have an account? "),
                  _createElementVNode("span", {
                    "data-bs-target": "#exampleModalToggle2",
                    "data-bs-toggle": "modal",
                    class: "text-primary"
                  }, " Sign Up")
                ])
              ], -1))
            ]),
            _cache[41] || (_cache[41] = _createElementVNode("div", { class: "d-flex justify-content-center align-items-center my-3" }, [
              _createElementVNode("div", { class: "line" }),
              _createElementVNode("div", { class: "text-gray mx-1" }, "Or"),
              _createElementVNode("div", { class: "line" })
            ], -1)),
            _createElementVNode("div", _hoisted_66, [
              _createElementVNode("button", {
                onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (guestPurchase()), ["prevent"])),
                class: "btn-normal justify-content-center d-flex align-items-center"
              }, [
                _createVNode(_component_Iconify, {
                  class: "primary",
                  icon: "solar:user-bold",
                  style: {"font-size":"24px"}
                }),
                _cache[40] || (_cache[40] = _createElementVNode("span", { class: "ms-2" }, "Continue as a guest", -1))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})