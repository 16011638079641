import { useToast } from "vue-toast-notification";

export const toggleSidebar = (state:any, displaySidebar: boolean) => {
  state.showsidebar = displaySidebar
};

export const setUser = (state: any, user: any) => {
  // console.log('llfl',user)
  state.user = user.data;
  // console.log( state.user);
  state.token = user.accessToken;
  var result = encodeURIComponent(JSON.stringify(user));
  localStorage.setItem("tao", result);
};

export const getUser = (state: any) => {
  // console.log('get')
  var data = localStorage.getItem("tao");
  // console.log('tao', data)
  if (data) {
    var js = JSON.parse(decodeURIComponent(data));
    state.user = js.data;
    state.token = js.accessToken;
    // console.log('dkdk', state);
  }
  // else {
  //   useToast().error("Token expired, please login again");
  // }
  // console.log("state", state);
};

export const logout = (state: any) => {
  window.localStorage.removeItem("tao");
  state.user = null;
  state.token = null;
  window.location.href = "/buy-energy-app";
};

export const setLoader = (state: any, showLoader: boolean) => {
  state.loader = showLoader;
};
