<script setup lang="ts">
import { ref, onMounted, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import MyAccount from "@/components/Dashboard/my-account.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import LoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";


const store = useStore();
const $toast = useToast();
const loading = ref(false);
const router= useRouter()



const userDetails = reactive ({
  data: {
created_at:"",
email:"",
first_name:"",
last_name:"",
last_updated:"",
phone:"",
 },
  accessToken: "",

});

const UpdateDetails = () => {
  store.commit("setLoader", true);
  loading.value = true;
  store
    .dispatch("patch", {
      endpoint: "/user",
      details: {
        first_name: store.state.user.first_name,
        last_name : store.state.user.last_name,
        phone: store.state.user.phone,
      },
    })
    .then((resp) => {
      store.commit("setLoader", false);
      loading.value = false;
      // console.log(resp)
      userDetails.data = resp.data.data;
      useToast().success("Profile updated successfully");
      window.setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
    .catch(() => {
      store.commit("setLoader", false);
      loading.value = false;
    });
};

const getUser = () => {
  store.commit("setLoader", true);
  store.dispatch("get", "/user").then((resp) => {
    // console.log(resp)
    store.commit("setLoader", false);
    userDetails.data = resp.data.data;
    userDetails.accessToken = store.state.token
    store.commit("setUser", userDetails)
    // console.log( userDetails );
  }).catch((err) => {
    store.commit("setLoader", true);
  })
};

onMounted(() => {
  if (store.state.user) {
    getUser();
  }

});
</script>

<template>
  <div class="page-card d-flex justify-content-center">
    <MyAccount></MyAccount>

    <div class="account-contents" v-if="store.state.user">
      <div class="mobile-screen">
        <div class="pb-2">
          <div class="back-icon e-icon me-3" @click.prevent="router.go(-1)">
            <Iconify icon="ep:arrow-left" />
          </div>
        </div>
      </div>

      <div class="desktop-screen">
        <div class="b-nav border-bottom">
          <h5>My Profile</h5>
        </div>
      </div>

      <div class="profile-page">
        <div class="mobile-screen">
          <div class="username d-flex align-items-center my-4">
            <div class="d-flex align-items-center">
              <div class="initials">
                <h4 class="text-primary uppercase">
                  {{ store.state.user.first_name[0]
                  }}{{ store.state.user.last_name[0] }}
                </h4>
              </div>
              <div class="ms-3">
                <h6 class="capitalize">
                  {{ store.state.user.first_name }}
                  {{ store.state.user.last_name }}
                </h6>
                <p class="profile-email">
                  {{ store.state.user.email }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <form action="">
          <div class="row g-4 mb-5">
            <div class="col-lg-6 col-sm-12">
              <div class="each-field">
                <label class="mb-2" for="email"
                  >First Name <span class="text-danger">*</span></label
                >
                <div>
                  <input
                    v-model="store.state.user.first_name"
                    type="text"
                    class="form-control border-gray bg-gray"
                    placeholder="e.g joe"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="each-field">
                <label class="mb-2" for="email"
                  >Last Name <span class="text-danger">*</span></label
                >
                <div>
                  <input
                    v-model="store.state.user.last_name"
                    type="text"
                    class="form-control border-gray bg-gray"
                    placeholder="e.g doe"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-sm-12">
              <div class="each-field">
                <label class="mb-2" for="email"
                  >Email Address <span class="text-danger">*</span></label
                >
                <div>
                  <input
                    v-model="store.state.user.email"
                    type="text"
                    class="form-control border-gray bg-gray"
                    placeholder="e.g joedoe@gmail.com"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-sm-12">
              <div class="each-field mb-3">
                <label for="meter" class="mb-2"
                  >Phone Number <span class="text-danger">*</span></label
                >
                <div class="input-group mb-3">
                  <div class="input-group-text" id="basic-addon1">
                    <Iconify
                      icon="twemoji:flag-nigeria"
                      style="width: 24px; height: 24x"
                    /><span class="pe-2" style="border-right: 1px solid gray">
                      +234</span
                    >
                  </div>
                  <input
                    v-model="store.state.user.phone"
                    type="tel"
                    class="form-control border-gray bg-gray"
                    placeholder="081*******"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="updateBtn">
            <button
              @click.prevent="UpdateDetails()"
              class="btn-primary px-4"
              :disabled="loading"
            >
              {{ loading ? "Updating..." : "Save Changes" }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
    <div class="d-flex justify-content-center my-5">
      <div class="text-center" style="width: 400px">
        <Iconify icon="lets-icons:flash-light" style="font-size: 28px" />
        <h5 class="my-4">You have not signed in</h5>
        <p class="p-text text-gray mb-4">
          Please create an account or sign in to have access to your account
        </p>

        <div class="desktop-screen">  
          <div class="d-flex align-items-center justify-content-center ">
            <button
              data-bs-target="#exampleModalToggleB"
              data-bs-toggle="modal"
              class="btn-primary auth-btn me-3"
          
            >
              Create an Account
            </button>
            <button
              data-bs-target="#exampleModalToggleC"
              data-bs-toggle="modal"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        </div>

        <div class="mobile-screen"> 
          <div class="d-flex align-items-center justify-content-center ">
            <button
              class="btn-primary auth-btn me-3"
              data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom10"
            aria-controls="offcanvasBottom10"
            >
              Create an Account
            </button>
            <button
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom11"
            aria-controls="offcanvasBottom11"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        
        </div>
      </div>
    </div>

    <LoginMob></LoginMob>
    <NewUserLogin></NewUserLogin>
  </div>
  </div>
</template>

<style lang="scss" scoped></style>
