import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/roof-2.png'
import _imports_1 from '@/assets/images/roof-3.jpg'


const _hoisted_1 = { class: "page-card" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "energy-text" }
const _hoisted_5 = { class: "audit-forms" }
const _hoisted_6 = { class: "image-side" }
const _hoisted_7 = { class: "form-container" }
const _hoisted_8 = { class: "row g-4" }
const _hoisted_9 = { class: "col-lg-6 col-sm-6" }
const _hoisted_10 = { class: "img-card" }
const _hoisted_11 = { class: "mobile-screen" }
const _hoisted_12 = {
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#offcanvasBottomD",
  "aria-controls": "offcanvasBottomD",
  class: "card-btn btn-primary-light d-flex justify-content-between align-items-center"
}
const _hoisted_13 = { class: "desktop-screen" }
const _hoisted_14 = {
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#offcanvasRight8",
  "aria-controls": "offcanvasRight8",
  class: "card-btn btn-primary-light d-flex justify-content-between align-items-center"
}
const _hoisted_15 = { class: "col-lg-6 col-sm-6" }
const _hoisted_16 = { class: "img-card" }
const _hoisted_17 = { class: "mobile-screen" }
const _hoisted_18 = {
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#offcanvasBottomE",
  "aria-controls": "offcanvasBottomE",
  class: "card-btn btn-primary-light d-flex justify-content-between align-items-center"
}
const _hoisted_19 = { class: "desktop-screen" }
const _hoisted_20 = {
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#offcanvasRight4",
  "aria-controls": "offcanvasRight4",
  class: "card-btn btn-primary-light d-flex justify-content-between align-items-center"
}
const _hoisted_21 = {
  key: 0,
  class: "view-request"
}
const _hoisted_22 = {
  "data-bs-toggle": "collapse",
  "data-bs-target": "#collapseExample",
  "aria-expanded": "false",
  "aria-controls": "collapseExample",
  class: "d-flex justify-content-between align-items-center bg-gray p-3 rounded"
}
const _hoisted_23 = {
  class: "collapse",
  id: "collapseExample"
}
const _hoisted_24 = { class: "card card-body p-0" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "d-flex justify-content-between align-items-center border-bottom p-3" }
const _hoisted_28 = { class: "d-flex align-items-center" }
const _hoisted_29 = { class: "desktop-screen" }
const _hoisted_30 = { class: "mobile-screen" }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = {
  key: 0,
  class: "d-flex justify-content-between align-items-center p-3"
}
const _hoisted_33 = { class: "d-flex align-items-center" }
const _hoisted_34 = { class: "desktop-screen" }
const _hoisted_35 = { class: "mobile-screen" }
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { class: "d-flex justify-content-center my-5" }
const _hoisted_38 = {
  class: "text-center",
  style: {"width":"400px"}
}

import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { formatDate } from "@/core/utils/helpers";
import OfficeAudit from "@/components/BottomSheet/Audit/OfficeForm/OfficeForm.vue";
import HomeAudit from "@/components/BottomSheet/Audit/HomeForm/AuditHome.vue";
import OfficeResponse from "@/components/BottomSheet/Audit/Response/office.vue";
import HomeResponse from "@/components/BottomSheet/Audit/Response/Home.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import NewLoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";

import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'energy-audit',
  setup(__props) {

const store = useStore();
const router = useRouter();
const loaded = ref(false);
const officeResponse = ref(false);
const homeResponse = ref(false);
const apiOfficeRes = ref({});
const apiHomeRes = ref({});
const apiOfficeResponse = ref({
  type: "",
  created_at: "",
});

const apiHomeResponse = ref({
  type: "",
  created_at: "",
});

const getOfficeResponse = () => {
  store.commit("setLoader", true);
  store.dispatch("get", `/auditForm/office`).then((resp) => {
    // console.log(resp);
    store.commit("setLoader", false);
    officeResponse.value = true;
    loaded.value = true;
    apiOfficeResponse.value = resp.data.data;
    
    apiOfficeRes.value = apiOfficeResponse.value;
    
  });
};
const getHomeResponse = () => {
  store.commit("setLoader", true);
  store.dispatch("get", `/auditForm/home`).then((resp) => {
    console.log(resp);
    store.commit("setLoader", false);
    homeResponse.value = true;
    loaded.value = true;
    apiHomeResponse.value = resp.data.data;
    apiHomeRes.value = apiHomeResponse.value;
  });
};

const setApiHomeResponse = (item: any) => {
  apiHomeRes.value = item;
};

const setApiOfficeResponse = (item: any) => {
  apiOfficeRes.value = item;
};

onMounted(() => {
  if (store.state.user) {
    getHomeResponse();
    getOfficeResponse();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (loaded.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_unref(store).state.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "back-icon e-icon me-3",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(router).go(-1)), ["prevent"]))
                  }, [
                    _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("div", null, [
                    _createElementVNode("p", { class: "fs-5 mb-3 energy" }, "Energy Audit"),
                    _createElementVNode("p", null, " This questionnaire will help us generate a basic energy needs report to design the optimal system to maximize your energy reliability and cost savings ")
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-gray mb-4" }, " Please proceed with an option below that best describes the location you want to power. ", -1)),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "img" }, [
                              _createElementVNode("img", {
                                src: _imports_0,
                                alt: ""
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "d-flex align-items-center" }, [
                                  _createElementVNode("div", { class: "red-dot me-2" }),
                                  _createElementVNode("p", { class: "text-primary" }, "Home")
                                ], -1)),
                                _createElementVNode("div", null, [
                                  _createVNode(_component_Iconify, {
                                    class: "primary",
                                    icon: "ep:arrow-right"
                                  })
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "d-flex align-items-center" }, [
                                  _createElementVNode("div", { class: "red-dot me-2" }),
                                  _createElementVNode("p", { class: "text-primary" }, "Home")
                                ], -1)),
                                _createElementVNode("div", null, [
                                  _createVNode(_component_Iconify, {
                                    class: "primary",
                                    icon: "ep:arrow-right"
                                  })
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "img" }, [
                              _createElementVNode("img", {
                                src: _imports_1,
                                alt: ""
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "d-flex align-items-center" }, [
                                  _createElementVNode("div", { class: "red-dot me-2" }),
                                  _createElementVNode("p", { class: "text-primary" }, "Office")
                                ], -1)),
                                _createElementVNode("div", null, [
                                  _createVNode(_component_Iconify, {
                                    class: "primary",
                                    icon: "ep:arrow-right"
                                  })
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, [
                                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "d-flex align-items-center" }, [
                                  _createElementVNode("div", { class: "red-dot me-2" }),
                                  _createElementVNode("p", { class: "text-primary" }, "Office")
                                ], -1)),
                                _createElementVNode("div", null, [
                                  _createVNode(_component_Iconify, {
                                    class: "primary",
                                    icon: "ep:arrow-right"
                                  })
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  (apiHomeResponse.value || apiOfficeResponse.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _cache[13] || (_cache[13] = _createElementVNode("span", null, "View your response history", -1)),
                          _createElementVNode("span", null, [
                            _createVNode(_component_Iconify, { icon: "oui:arrow-down" })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "d-flex justify-content-between align-items-center p-3" }, [
                              _createElementVNode("p", null, "Type of space"),
                              _createElementVNode("p", { class: "me-5" }, "Date")
                            ], -1)),
                            (homeResponse.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                  (apiHomeResponse.value)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                        _createVNode(HomeResponse, { apiHomeRes: apiHomeRes.value }, null, 8, ["apiHomeRes"]),
                                        _createElementVNode("div", _hoisted_27, [
                                          _createElementVNode("p", null, _toDisplayString(apiHomeResponse.value.type), 1),
                                          _createElementVNode("div", _hoisted_28, [
                                            _createElementVNode("p", null, _toDisplayString(_unref(formatDate)(apiHomeResponse.value.created_at)), 1),
                                            _createElementVNode("div", _hoisted_29, [
                                              _createVNode(_component_Iconify, {
                                                onClick: _cache[1] || (_cache[1] = ($event: any) => (setApiHomeResponse(apiHomeResponse.value))),
                                                icon: "ri:more-line",
                                                "data-bs-toggle": "offcanvas",
                                                "data-bs-target": "#offcanvasRight7",
                                                "aria-controls": "offcanvasRight7",
                                                class: "ms-2"
                                              })
                                            ]),
                                            _createElementVNode("div", _hoisted_30, [
                                              _createVNode(_component_Iconify, {
                                                onClick: _cache[2] || (_cache[2] = ($event: any) => (setApiHomeResponse(apiHomeResponse.value))),
                                                icon: "oui:arrow-right",
                                                "data-bs-toggle": "offcanvas",
                                                "data-bs-target": "#offcanvasBottomF",
                                                "aria-controls": "offcanvasBottomF",
                                                class: "ms-2"
                                              })
                                            ])
                                          ])
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true),
                            (officeResponse.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                  _createVNode(OfficeResponse, { apiOfficeRes: apiOfficeRes.value }, null, 8, ["apiOfficeRes"]),
                                  (apiOfficeResponse.value)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                        _createElementVNode("p", null, _toDisplayString(apiOfficeResponse.value.type), 1),
                                        _createElementVNode("div", _hoisted_33, [
                                          _createElementVNode("p", null, _toDisplayString(_unref(formatDate)(apiOfficeResponse.value.created_at)), 1),
                                          _createElementVNode("div", _hoisted_34, [
                                            _createVNode(_component_Iconify, {
                                              onClick: _cache[3] || (_cache[3] = ($event: any) => (setApiOfficeResponse(apiOfficeResponse.value))),
                                              icon: "ri:more-line",
                                              style: {"font-weight":"700"},
                                              "data-bs-toggle": "offcanvas",
                                              "data-bs-target": "#offcanvasRight6",
                                              "aria-controls": "offcanvasRight6",
                                              class: "ms-2"
                                            })
                                          ]),
                                          _createElementVNode("div", _hoisted_35, [
                                            _createVNode(_component_Iconify, {
                                              onClick: _cache[4] || (_cache[4] = ($event: any) => (setApiOfficeResponse(apiOfficeResponse.value))),
                                              icon: "oui:arrow-right",
                                              style: {"font-weight":"700"},
                                              "data-bs-toggle": "offcanvas",
                                              "data-bs-target": "#offcanvasBottomG",
                                              "aria-controls": "offcanvasBottomG",
                                              class: "ms-2"
                                            })
                                          ])
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(OfficeAudit),
                _createVNode(HomeAudit)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_36, [
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("div", _hoisted_38, [
              _createVNode(_component_Iconify, {
                icon: "lets-icons:flash-light",
                style: {"font-size":"28px"}
              }),
              _cache[15] || (_cache[15] = _createStaticVNode("<h5 class=\"my-4\">You have not signed in</h5><p class=\"p-text text-gray mb-4\"> Please create an account or sign in to have access to your account settings </p><div class=\"desktop-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button data-bs-target=\"#exampleModalToggleB\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn me-3\"> Create an Account </button><button data-bs-target=\"#exampleModalToggleC\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn\"> Login </button></div></div><div class=\"mobile-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button class=\"btn-primary auth-btn me-3\" data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom10\" aria-controls=\"offcanvasBottom10\"> Create an Account </button><button data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom11\" aria-controls=\"offcanvasBottom11\" class=\"btn-primary auth-btn\"> Login </button></div></div>", 4))
            ])
          ]),
          _createVNode(NewUserLogin),
          _createVNode(NewLoginMob)
        ]))
  ]))
}
}

})